<template>
  <div>
    <b-card class="card-accent-primary" header-tag="header">
      <div slot="header">
        <strong>Kullanıcılar Listesi</strong>
      </div>
      <b-row>
        <b-col cols="2">Eklemek İçin Filtrele</b-col>
        <b-col cols="6">
          <b-form-input v-model="search_text"></b-form-input>
        </b-col>
        <b-col cols="4">
          <b-button block variant="primary" @click="f_addAllUsers()"><i class="fa fa-edit"></i> Tümünü Ekle</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-row>
            <b-col cols="12">
              <table class="userListForWisdomTable">
                <tr class="userListForWisdomTr">
                  <th class="userListForWisdomTh">Info</th>
                  <th class="userListForWisdomTh">Kullanıcı Adı</th>
                  <th class="userListForWisdomTh">Ad-Soyad</th>
                  <th class="userListForWisdomTh">Grup</th>
                  <th class="userListForWisdomTh">Ekle</th>
                </tr>
                <template v-for="(user, user_ind) in d_textUserList">
                  <tr class="userListForWisdomTr" :style="f_calculateRowStyle(user_ind)">
                    <td class="userListForWisdomTd">
                      <template v-if="d_infoObj[user.username]">
                        <template v-for="(info_data, info_ind) in d_infoObj[user.username]">
                          <b-badge :key="'info_badge_' + user.username + '_' + info_ind" :variant="info_data.variant">
                            <i :key="'info_i_' + user.username + '_' + info_ind" :class="[info_data.icon]" :title="info_data.label"></i>
                          </b-badge>
                        </template>
                      </template>
                    </td>
                    <td class="userListForWisdomTd">
                      {{ user.username }}
                    </td>
                    <td class="userListForWisdomTd">
                      {{ user.first_name + ' ' + user.last_name }}
                    </td>
                    <td class="userListForWisdomTd">
                      {{ user.group }}
                    </td>
                    <td class="userListForWisdomTd">
                      <b-button block variant="primary" @click="f_addThisUser(user_ind)"><i class="fa fa-edit"></i> Ekle</b-button>
                    </td>
                  </tr>
                </template>
              </table>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">
          <b-row>
            <b-col cols="12">
              <table class="userListForWisdomTable">
                <tr class="userListForWisdomTr">
                  <th class="userListForWisdomTh">Kaldır</th>
                  <th class="userListForWisdomTh">Kullanıcı Adı</th>
                  <th class="userListForWisdomTh">Ad-Soyad</th>
                  <th class="userListForWisdomTh">Grup</th>
                  <th class="userListForWisdomTh">Info</th>
                </tr>
                <template v-for="(user, user_ind) in p_selectedUserList">
                  <tr class="userListForWisdomTr" :style="f_calculateRowStyle(user_ind)">
                    <td class="userListForWisdomTd">
                      <b-button block variant="primary" @click="f_removeThisUser(user_ind)"><i class="fa fa-edit"></i> Kaldır</b-button>
                    </td>
                    <td class="userListForWisdomTd">
                      {{ user.username }}
                    </td>
                    <td class="userListForWisdomTd">
                      {{ user.first_name + ' ' + user.last_name }}
                    </td>
                    <td class="userListForWisdomTd">
                      {{ user.group }}
                    </td>
                    <td class="userListForWisdomTd">
                      <template v-if="d_infoObj[user.username]">
                        <template v-for="(info_data, info_ind) in d_infoObj[user.username]">
                          <b-badge :key="'info_badge_' + user.username + '_' + info_ind" :variant="info_data.variant">
                            <i :key="'info_i_' + user.username + '_' + info_ind" :class="[info_data.icon]" :title="info_data.label"></i>
                          </b-badge>
                        </template>
                      </template>
                    </td>
                  </tr>
                </template>
              </table>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import _nav from '@/_nav';
import auth from '@/auth';
// import Vue from 'vue';
import { mapGetters } from 'vuex';
// import Vue from 'vue';
import {
  default as router
} from '@/router';
// import { API_BASE_URL } from '@/config';
import UserService from '@/services/users';
import { ClsSearch } from '@/services/public/search';

export default {
  name: 'UserSelectionModal',
  computed: {
    ...mapGetters({})
  },
  props: {
    p_selectedUserList: {
      type: Array,
      required: true
    },
    p_filterData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      search_text: '',
      d_textUserList: [],
      d_filteredUserList: [],
      d_filteredUserListBckup: [],
      d_infoObj: {}
    };
  },
  created: function () {
    this.f_createInfoData();
    UserService.get_users_with_filter(this.p_filterData)
      .then(resp => {
        this.d_textUserList = JSON.parse(JSON.stringify(resp.data.result));
        this.d_filteredUserList = JSON.parse(JSON.stringify(resp.data.result));
        this.d_filteredUserListBckup = JSON.parse(JSON.stringify(resp.data.result));
      });
  },
  methods: {
    f_createInfoData: function () {
      if (this.p_filterData.info) {
        for (let info_val in this.p_filterData.info) {
          for (let i in this.p_filterData.info[info_val].list) {
            let username = this.p_filterData.info[info_val].list[i];
            if (!this.d_infoObj[username]) {
              this.d_infoObj[username] = [];
            }
            this.d_infoObj[username].push({ 'icon': this.p_filterData.info[info_val].icon, 'variant': this.p_filterData.info[info_val].variant, 'label': this.p_filterData.info[info_val].label, 'value': this.p_filterData.info[info_val].value });
          }
        }
      }
    },
    f_addAllUsers: function () {
      this.p_selectedUserList = JSON.parse(JSON.stringify(this.d_filteredUserListBckup));
      this.d_filteredUserList = [];
      this.f_searchText();
    },
    f_addThisUser: function (user_ind) {
      this.p_selectedUserList.push(this.d_textUserList[user_ind]);
      let filtered_index = this.d_filteredUserList.map(function (e) {
        return e.username;
      }).indexOf(this.d_textUserList[user_ind].username);
      this.d_filteredUserList.splice(filtered_index, 1);
      this.f_searchText();
    },
    f_removeThisUser: function (user_ind) {
      this.d_filteredUserList.push(this.p_selectedUserList[user_ind]);
      this.p_selectedUserList.splice(user_ind, 1);
      this.f_searchText();
    },
    f_calculateRowStyle: function (user_ind) {
      if (user_ind % 2 === 0) {
        return '';
      } else {
        return 'background-color: #ececec;';
      }
    },
    f_searchText: function () {
      this.d_textUserList = ClsSearch.list(this.search_text, this.d_filteredUserList, this.d_textUserList, ['username', 'first_name', 'last_name']);
      // this.$forceUpdate();
    }
  },
  watch: {
    'search_text': function () {
      this.f_searchText();
    }
  },
  components: {}
};

</script>

<style>
.userListForWisdomTable {
  border: solid 1px;
  width: 100%;
}

.userListForWisdomTr {
  border: solid 1px;
}

.userListForWisdomTd {
  border: solid 1px;
}

.userListForWisdomTh {
  border: solid 1px;
  text-align: center;
  background-color: #ececec;
}

</style>

