<template>
  <div>
    <b-card v-if="d_showStats" no-body header-tag="header">
      <b-card no-body header-tag="header" style="margin-bottom: 0px;">
        <div id="case_table">
          <table style="width: 100%;">
            <thead>
              <tr>
                <th style="text-align: center; background-color: #ececec;">
                  <img src="@/icon/2209527.png" class="img-rounded img-responsive" style="width: 3em;" />
                </th>
                <th v-for="(col, col_ind) in d_organizationStat.types" style="white-space: nowrap; text-align: center; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: #ececec;">
                  {{ col.label }}
                </th>
                <th style="white-space: nowrap; text-align: center; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: #ececec;">
                  Toplam
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(row, row_ind) in d_organizationStat.stats">
                <tr style="border-bottom: solid 1px #c1c7cc;">
                  <td style="text-align: center; border-right: solid 1px #c3d8d8; background-color: #ececec;" onMouseOver="this.style.color='red'" onMouseOut="this.style.color='black'">
                    <img :src="row.picture ? row.picture : 'img/doctor_man.png'" class="img-avatar" width="20" height="20" />
                    {{ row.label }}
                  </td>
                  <template v-for="(col, col_ind) in row.status">
                    <td style="white-space: nowrap; text-align: center; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8;" onMouseOver="this.style.color='red'" onMouseOut="this.style.color='black'">
                      {{ col > 0 ? col + ' (%' + ((col / row.total) * 100).toFixed(2) + ') ' : col }}
                    </td>
                  </template>
                  <td style="white-space: nowrap; text-align: center; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8;" onMouseOver="this.style.color='red'" onMouseOut="this.style.color='black'">
                    {{row.total}}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import OrganizationService from '@/services/organization';
import { mapGetters } from 'vuex';
export default {
  name: 'OrganizationTaskStats',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {},
  props: {
    p_organizationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      d_organizationStat: { 'types': [], 'stats': [] },
      d_showStats: false
    };
  },
  created: function () {
    let data = {}
    data.organization_id = this.p_organizationId;
    OrganizationService.get_organization_task_stats(data)
      .then(resp => {
        if (resp.data.status === 'success') {
          this.d_organizationStat = resp.data.result;
        }
        this.d_showStats = true;
      });
  },
  beforeMount () {},
  mounted: function () {},
  methods: {},
  watch: {}
};

</script>

