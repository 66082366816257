<template>
  <div>
    <b-row v-if="show_mode === 'vSelect'">
      <b-col cols="12">
      </b-col>
    </b-row>
    <b-row v-if="show_mode === 'treeView'" style="padding: 3px;" :key="tree_list_type">
      <b-col v-if="left_tree_list_show" sm="12" :lg="f_showTreeListStyleLeft()">
        <b-card header-tag="header" footer-tag="footer" no-body>
          <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
            <b-row>
              <b-col cols="12">
                <i class="fa fa-th"></i>
                <span v-if="tree_list_data.tree_name && tree_list_data.tree_name.label">{{ tree_list_data.tree_name.label }}</span>
                <span v-else>Başlık henüz tanımlanmadı</span>
                <input type="text" v-model="search_text" placeholder="Filtrele" style="margin-left: 10px; margin-right: 10px; padding-left: 5px; width: 100px; height: 100%;">
                <template v-if="mode === 'edit'">
                  <b-button v-if="tree_list_type === 'organization'" class="pull-right" @click="left_tree_list_show ? left_tree_list_show = false : left_tree_list_show = true" variant="secondary" size="lg" style="margin-right: 3px;"><i class="fa fa-hand-o-left"></i></b-button>
                  <b-button v-if="tree_list_type === 'organization'" class="pull-right" @click="f_saveOrganization()" variant="primary" size="lg" style="margin-right: 3px;" title="Kaydet"> <i class="fa fa-save"></i></b-button>
                  <b-button v-if="tree_list_type !== 'organization'" class="pull-right" @click="f_saveTreeListData()" variant="primary" size="lg" style="margin-right: 3px;" title="Kaydet"> <i class="fa fa-save"></i></b-button>
                  <b-button class="pull-right" @click="fResetTreeListData()" variant="warning" size="lg" style="margin-right: 3px;" title="Değişiklikleri Geri Al (Kaydettikten sonra geri alınamaz!)"> <i class="fa fa-reply"></i> </b-button>
                  <b-button class="pull-right" @click="show_tree_data_settings = true" variant="secondary" size="lg" style="margin-right: 3px;" title="Ayarlar"> <i class="fa fa-sun-o"></i></b-button>
                  <b-button v-if="(tree_list_type === 'organization' && f_controlOnlyMyPermission('can_manage_sub_layer')) || (tree_list_type !== 'organization' && tree_list_type !== 'task_tree_list')" class="pull-right" @click="item_adding_mode ? item_adding_mode = false : item_adding_mode = true" variant="secondary" size="lg" style="margin-right: 3px;" title="Eleman Ekleme Modu"><i class="fa fa-plus" :style="item_adding_mode ? 'color:green;' : 'color:red;'"></i></b-button>
                </template>
              </b-col>
            </b-row>
          </b-card-header>
          <b-row v-if="show_items_on_top" style="margin: 0px; min-height: 20px;">
            <b-col cols="12">
              <b-badge v-for="(item, item_ind) in selected_items_data_list" :key="'selected_items_data_list_' + item_ind" :variant="item.isTitle === 'yes' ? 'danger' : 'success'" class="pull-right" style="padding: 3px; color: black; font-size: 10px;">
                {{ item.label }}
              </b-badge>
            </b-col>
          </b-row>
          <div v-if="tree_list_data.id" :style="CalculateTreeStyle()">
            <tree-layer :item_adding_mode="item_adding_mode" :tree_list_data="tree_list_data" :selected_items="selected_items" :my_item_index_list="my_item_index_list" :select_type="select_type" :mode="mode" :selected_item_data="selected_item_data" :change="change" :move_item="move_item" :pdt="pdt" :edit_user="edit_user" :tree_list_type="tree_list_type" :follow_up_location="follow_up_location" :d_parentList="d_parentList" :searchIndexList="searchIndexList" :search_text="search_text" :show_selected_item_parents="show_selected_item_parents"></tree-layer>
          </div>
        </b-card>
      </b-col>
      <b-col sm="12" :lg="f_showTreeListStyleRight()" v-if="mode === 'edit'">
        <template v-if="tree_list_type === 'organization'">
          <b-card no-body :style="'height:' + (d_device.height - 115).toString() +  'px;'">
            <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
              <b-row>
                <b-col sm="12" md="10">
                  <b-button v-if="!left_tree_list_show" @click="left_tree_list_show = true" variant="primary" size="lg" style="margin-right: 3px;"><i class="fa fa-hand-o-right"></i></b-button>
                  Organizasyon [ {{ tree_list_data.name }} ]
                  <strong v-if="selected_item_data.index">
                    <img src="@/icon/9370.png" width="30px" style="border-radius: 3px;" />
                    {{ selected_item_data.label.toLocaleUpperCase('tr') }}<small><i> {{ f_getIndexListAsString([selected_item_data.index]) }} </i></small>
                  </strong>
                </b-col>
                <b-col sm="12" md="2">
                  <b-dropdown class="pull-right" variant="primary">
                    <b-dropdown-item @click="f_showOrganizationStats()"><i class="fa fa-plus"></i>Organizasyon istatistiklerini görüntüle</b-dropdown-item>
                    <b-dropdown-item v-if="selected_item_data && selected_item_data.index && f_controlMyLayerPermission('can_create_task', true)" @click="f_addNewTask('wdm17', 'assigned', 'user', selected_item_data.index, 'yes')"><i class="fa fa-plus"></i>Seçili katmana yeni görev ata</b-dropdown-item>
                    <b-dropdown-item v-if="selected_item_data && selected_item_data.index && selected_item_data.perms && selected_item_data.perms.indexOf('can_create_task') !== -1 && f_controlOnlyMyPermission('can_recommend_task')" @click="f_addNewTask('wdm17', 'recommended', 'user')"><i class="fa fa-info"></i>Seçili katmana yeni görev öner</b-dropdown-item>
                    <b-dropdown-item v-if="f_controlOnlyMyPermission('can_create_task')" @click="f_addNewTask('wdm17', 'not_assigned', 'not_user')"><i class="fa fa-plus"></i> Henüz atanmamış yeni görev ekle</b-dropdown-item>
                    <b-dropdown-item v-if="selected_item_data && selected_item_data.index && f_controlMyLayerPermission('can_create_task', true)" @click="f_deleteTasksInOrganization('yes')"><i class="fa fa-trash"></i>Seçili Katman ve altındaki tüm görevleri temizle</b-dropdown-item>
                    <b-dropdown-item v-if="selected_item_data && selected_item_data.index && f_controlMyLayerPermission('can_create_task', true)" @click="f_deleteTasksInOrganization('no')"><i class="fa fa-trash"></i>Seçili Katman altındaki tüm görevleri temizle (Seçili katmanı silmez)</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-card-header>
            <b-row>
              <b-col sm="12" md="12">
                <b-navbar style="padding: 0px;">
                  <b-nav pills>
                    <b-nav-item v-if="selected_item_data && selected_item_data.index" @click="d_selectedOrganizationTab = 'user_details'" :active="d_selectedOrganizationTab === 'user_details'">
                      Genel Bilgileri
                    </b-nav-item>
                    <b-nav-item v-if="selected_item_data && selected_item_data.index" @click="d_selectedOrganizationTab = 'user_task_list'" :active="d_selectedOrganizationTab === 'user_task_list'">
                      Kullanıcı Görevleri
                    </b-nav-item>
                    <b-nav-item @click="d_selectedOrganizationTab = 'all_task_list'" :active="d_selectedOrganizationTab === 'all_task_list'">
                      Tüm Görevler
                    </b-nav-item>
                    <b-nav-item @click="d_selectedOrganizationTab = 'about_organization'" :active="d_selectedOrganizationTab === 'about_organization'">
                      Hakkında
                    </b-nav-item>
                    <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('administrator') !== -1)" @click="d_selectedOrganizationTab = 'drug_medical_equipment'" :active="d_selectedOrganizationTab === 'drug_medical_equipment'">
                      İlaç & Malzeme Ürün
                    </b-nav-item>
                    <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)" @click="d_selectedOrganizationTab = 'patient_data_project'" :active="d_selectedOrganizationTab === 'patient_data_project'">
                      Hasta Data Projesi
                    </b-nav-item>
                    <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)" @click="d_selectedOrganizationTab = 'send_email_to_users'" :active="d_selectedOrganizationTab === 'send_email_to_users'">
                      E-Mail
                    </b-nav-item>
                    <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || f_controlOnlyMyPermission('can_invite_users'))" @click="d_selectedOrganizationTab = 'send_invitation'" :active="d_selectedOrganizationTab === 'send_invitation'">
                      Davet Gönderimi
                    </b-nav-item>
                  </b-nav>
                </b-navbar>
              </b-col>
            </b-row>
            <template v-if="d_selectedOrganizationTab === 'about_organization'">
              <b-card no-body>
                <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                  Organizasyon Hakkında
                </b-card-header>
                <b-row>
                  <b-col sm="12" md="12">
                    {{ tree_list_data.about }}
                  </b-col>
                </b-row>
              </b-card>
            </template>
            <template v-if="d_selectedOrganizationTab === 'drug_medical_equipment'">
              <b-card no-body>
                <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                  Ürün Atama İşlemleri
                </b-card-header>
                <b-row style="padding: 5px;">
                  <b-col sm="12" md="6">
                    Henüz Atanmamış Ürün Sayısı
                  </b-col>
                  <b-col sm="12" md="6">
                    {{ not_assigned_brand_count }}
                  </b-col>
                </b-row>
                <b-row style="padding: 5px;">
                  <b-col sm="12" md="6">
                    Kaç Ürün Dağıtılacak
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-input type="number" name="target_sharing_brand_count" placeholder="Kaç ürün dağıtılsın" v-model="share_brand_data.target" :max="not_assigned_brand_count > 500 ? 500 : not_assigned_brand_count" :min="0"></b-form-input>
                  </b-col>
                </b-row>
                <b-row style="padding: 5px;">
                  <b-col sm="12" md="6">
                    Öncelik Seçiniz
                  </b-col>
                  <b-col sm="12" md="6">
                    <v-select v-model="share_brand_data.priority" :options="d_priorityList"></v-select>
                  </b-col>
                </b-row>
                <b-row style="padding: 5px;">
                  <b-col sm="12" md="6">
                    Görev Açıklamasını Belirtiniz
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-textarea v-model="share_brand_data.note" rows="5"></b-textarea>
                  </b-col>
                </b-row>
                <b-row style="padding: 5px;">
                  <b-col sm="12" md="6">
                    Henüz atanmayan tüm ürünleri seçili kullanıcının altındaki kişilere düzenleme yapmak için paylaştır (Bir kez başka bir sebeple atanmışsa bile atama yapmaz)
                  </b-col>
                  <b-col sm="12" md="6">
                    <template v-if="selected_item_data.index">
                      <b-button variant="primary" @click="f_shareUnassignedBrandsToSelectedUsers('only_sub_layers', 'random')">Atama yap</b-button>
                    </template>
                    <template v-else>
                      <span style="color: red;">Lütfen önce bir katman seçimi yapınız.</span>
                    </template>
                  </b-col>
                </b-row>
                <b-row style="padding: 5px;">
                  <b-col sm="12" md="6">
                    İçerik Listesi Oluştur
                  </b-col>
                  <b-col sm="12" md="6">
                    <template v-if="selected_item_data.index">
                      <b-button variant="primary" @click="f_prepareBrandsFromIngredients()">İçerik Listesi Ekranı</b-button>
                    </template>
                    <template v-else>
                      <span style="color: red;">Lütfen önce bir katman seçimi yapınız.</span>
                    </template>
                  </b-col>
                </b-row>
              </b-card>
            </template>
            <template v-if="d_selectedOrganizationTab === 'patient_data_project'">
              <b-card no-body>
                <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                  Hasta Data Projesi Seçimi (HENÜZ HAZIR DEĞİL)
                </b-card-header>
                <b-row style="padding: 5px;">
                  <b-col sm="12" md="6">
                    Henüz Atanmamış Ürün Sayısı
                  </b-col>
                  <b-col sm="12" md="6">
                    <!-- {{ not_assigned_brand_count }} -->
                  </b-col>
                </b-row>
                <!-- <b-row style="padding: 5px;">
                  <b-col sm="12" md="6">
                    Hasta seçim türünü seçiniz
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-select class="form-control-warning" id="group_value" v-model="d_patientListType" required>
                      <option value="hospital">Hastane</option>
                      <option value="project">Proje</option>
                    </b-form-select>
                  </b-col>
                </b-row> -->
                <b-row style="padding: 5px;">
                  <b-col sm="12" md="6">
                    Hasta Data Projesi Seçin
                  </b-col>
                  <b-col sm="12" md="6">
                    <v-select v-model="d_selectedPatientDataProject" :options="d_patientDataProjectList"></v-select>
                  </b-col>
                </b-row>
                <!-- <b-row v-if="d_patientListType === 'project'" style="padding: 5px;">
                  <b-col sm="12" md="12">
                    <b-form-input id="search_patient" placeholder="Hasta Ad Soyad, WisdomEra hasta no, Medin Hasta No, Tc/Passport no ile arama yapabilirsiniz" type="text" v-model="d_searchText"></b-form-input>
                  </b-col>
                </b-row> -->
                <!-- <b-row style="padding: 5px;">
                  <b-col cols="12">
                    <b-pagination v-if="d_projectPatientList && d_projectPatientList.list" class="pull-right" aria-controls="myProtocolsTable" v-model="d_patientListTableData['current']" :total-rows="d_projectPatientList.list.length" :per-page="d_patientListTableData['per-page']"></b-pagination>
                    <b-table id="searchPatientTable" :per-page="d_patientListTableData['per-page']" :current-page="d_patientListTableData['current']" thead-tr-class="" head-variant="dark" striped responsive stacked="sm" ref="ref_searchPatient" bordered small hover :items="d_projectPatientList.list" :filter="d_searchText" :filter-included-fields="['firstname', 'lastname','record_id', 'personel_number', 'passport_number']" :fields="searchPatientColumns" selectable select-mode="single" selectedVariant="success" @row-selected="" @row-contextmenu="" @row-clicked="" @row-hovered="" @row-unhovered="">
                      <template v-slot:cell(actions)="row">
                        <b-dropdown variant="success"></b-dropdown>
                      </template>
                      <template v-slot:cell(rowNumber)="row">
                        {{ ((d_patientListTableData['current'] - 1) * d_patientListTableData['per-page']) + row.index + 1 }}
                      </template>
                      <template v-slot:cell(name)="row">
                        {{ row.item.firstname.toLocaleLowerCase('tr') + ' ' + row.item.lastname.toLocaleLowerCase('tr') }}
                      </template>
                      <template v-slot:cell(sex)="row">
                        <i v-if="row.item.sex === '0'" class="fa fa-mars"></i>
                        <i v-else class="fa fa-venus"></i> {{ row.item.sex === '0' ? 'Erkek' : 'Kadın' }}
                      </template>
                      <template v-slot:cell(citizen_type)="row">
                        {{ row.item.citizen_type === 'citizen' ? 'Türkiye' : 'Yabancı' }}
                      </template>
                    </b-table>
                  </b-col>
                </b-row> -->
                <b-row style="padding: 5px;">
                  <b-col sm="12" md="6">
                    Henüz atanmayan tüm ürünleri seçili kullanıcının altındaki kişilere düzenleme yapmak için paylaştır (Bir kez başka bir sebeple atanmışsa bile atama yapmaz)
                  </b-col>
                  <b-col sm="12" md="6">
                    <template v-if="selected_item_data && selected_item_data.index && d_selectedPatientDataProject && d_selectedPatientDataProject.value && d_selectedPatientDataProject.value !== ''">
                      <b-button variant="primary" @click="f_assignNotAssignedProjectPatients()"><i class="fa fa-search"></i> atamaya başla bakalım</b-button>
                    </template>
                    <template v-else>
                      <span style="color: red;">Lütfen önce bir katman ve data projesi seçimi yapınız.</span>
                    </template>
                  </b-col>
                </b-row>
                <!-- <b-row style="padding: 5px;">
                  <b-col sm="12" md="6">
                    İçerik Listesi Oluştur
                  </b-col>
                  <b-col sm="12" md="6">
                    <template v-if="selected_item_data.index">
                      <b-button variant="primary">İçerik Listesi Ekranı</b-button>
                    </template>
                    <template v-else>
                      <span style="color: red;">Lütfen önce bir katman seçimi yapınız.</span>
                    </template>
                  </b-col>
                </b-row> -->
              </b-card>
            </template>
            <template v-if="d_selectedOrganizationTab === 'send_invitation'">
              <b-card no-body>
                <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                  Davetiye Gönderim Ekranı
                </b-card-header>
                <b-row style="padding: 5px;">
                  <b-col cols="12">
                    <b-form-group label="Davet Metni" label-for="invite_text" :label-cols="3" validated>
                      <b-form-input type="textare" class="form-control-warning" id="invite_text" v-model="d_invitationData.invite_text" required></b-form-input>
                      <b-form-valid-feedback></b-form-valid-feedback>
                      <b-form-invalid-feedback>Lütfen davet metni giriniz</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row style="padding: 5px;">
                  <b-col cols="12">
                    <b-button block variant="primary" @click="f_showInviteUserSelectionModal()"><i class="fa fa-search"></i> Alıcı Kullanıcı Seçimi ({{ d_willInviteUserList.length }} adet seçili)</b-button>
                  </b-col>
                </b-row>
                <b-row style="padding: 5px;">
                  <b-col cols="12">
                    <b-button :disabled="d_willInviteUserList.length === 0 || !d_invitationData.invite_text" block variant="primary" @click="f_sendOrganizationInvites()"><i class="fa fa-envelope"></i> Davetiyeleri gönder</b-button>
                  </b-col>
                </b-row>
              </b-card>
            </template>
            <template v-if="d_selectedOrganizationTab === 'send_email_to_users'">
              <b-card no-body>
                <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                  Mail Gönderme Ekranı
                </b-card-header>
                <b-row style="padding: 5px;">
                  <b-col sm="12" md="6">
                    E-mail Gönderimi
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-button disabled variant="primary" @click="f_sendEmailToOrganizationUsers()"><i class="fa fa-search"></i> e-mail gönder</b-button>
                  </b-col>
                </b-row>
              </b-card>
            </template>
            <template v-if="tree_list_data.id && d_selectedOrganizationTab === 'user_task_list' && selected_item_data.isTitle && selected_item_data.isTitle === 'no'">
              <task-list :my_item_index_list="my_item_index_list" :left_tree_list_show="left_tree_list_show" :organization_data="tree_list_data" :organization_tree="true" :organization_id="tree_list_data.id" :selected_organization_item="selected_item_data" :watch_task_list="d_watchTaskList"></task-list>
            </template>
            <template v-if="tree_list_data.id && d_selectedOrganizationTab === 'all_task_list'">
              <task-list :my_item_index_list="my_item_index_list" :left_tree_list_show="left_tree_list_show" :organization_data="tree_list_data" :organization_tree="true" :organization_id="tree_list_data.id" :selected_organization_item="selected_item_data" :watch_task_list="d_watchTaskList"></task-list>
            </template>
            <template v-if="d_selectedOrganizationTab === 'user_details' && selected_items.length > 0">
              <b-card no-body>
                <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                  Genel
                </b-card-header>
                <b-row style="background: #fbffdf; margin: 0px;">
                  <b-col sm="12" md="2" v-if="selected_item_data.isTitle === 'no'">
                    <template v-if="d_allOrganizationUsers[selected_item_data.value] && d_allOrganizationUsers[selected_item_data.value].pic">
                      <img :src="d_allOrganizationUsers[selected_item_data.value].pic" width="100%" style="border-radius: 3px; margin: 2px;" />
                    </template>
                    <template v-else>
                      <img src="@/icon/9370.png" width="100%" style="border-radius: 3px;" />
                    </template>
                  </b-col>
                  <b-col sm="12" :md="selected_item_data.isTitle === 'yes' ? '12' : '10'">
                    <b-form-group label="" label-for="group_type" label-cols="3" validated>
                      <template slot="label"> <i class="fa fa-hand-o-right"></i> Grup Başlığı mı? </template>
                      <template v-if="f_controlMyLayerPermission('can_manage_sub_layer') && (selected_item_data.task_list === undefined || (selected_item_data.task_list !== undefined && selected_item_data.task_list.length === 0))">
                        <b-form-select class="form-control-warning" id="group_value" v-model="selected_item_data.isTitle" required>
                          <option value="yes">{{ $t('wdm16.7249') }}</option>
                          <option value="no">{{ $t('wdm16.76') }}</option>
                        </b-form-select>
                        <b-form-valid-feedback></b-form-valid-feedback>
                        <b-form-invalid-feedback>Sadece başlık olarak kullanmak ve grup oluşturmak istiyorsanız evet seçeneğini seçiniz.</b-form-invalid-feedback>
                      </template>
                      <template v-else>
                        {{ selected_item_data.isTitle === 'yes' ? 'Evet' : 'Hayır' }}
                      </template>
                    </b-form-group>
                    <b-form-group v-if="selected_item_data.isTitle && selected_item_data.isTitle === 'yes'" label="" label-for="group_label" label-cols="3" validated>
                      <template slot="label"> <i class="fa fa-hand-o-right"></i> Başlık </template>
                      <template v-if="f_controlMyLayerPermission('can_manage_sub_layer') && (selected_item_data.task_list === undefined || (selected_item_data.task_list !== undefined && selected_item_data.task_list.length === 0))">
                        <b-form-input type="text" class="form-control-warning" id="group_label" v-model="selected_item_data.label" required></b-form-input>
                        <span v-if="label_alert" style="color: red;"> AYNI DEĞER MEVCUT BİLGİNİZE </span>
                        <b-form-valid-feedback></b-form-valid-feedback>
                        <b-form-invalid-feedback>Başlığı giriniz</b-form-invalid-feedback>
                      </template>
                      <template v-else>
                        {{ selected_item_data.label }}
                      </template>
                    </b-form-group>
                    <b-form-group v-if="selected_item_data.isTitle && selected_item_data.isTitle === 'no'" label="" label-for="organization_user" label-cols="3" validated>
                      <template slot="label"> <i class="fa fa-hand-o-right"></i> Kullanıcı </template>
                      <template v-if="f_controlMyLayerPermission('can_manage_sub_layer') || (selected_item_data.index === '0' && my_item_index_list.indexOf('0') !== -1)">
                        <v-select v-model="selected_item_data.user" :options="d_userListWithDepartments"></v-select>
                        <b-form-valid-feedback></b-form-valid-feedback>
                        <b-form-invalid-feedback>Kullanıcı seçiniz</b-form-invalid-feedback>
                      </template>
                      <template v-else>
                        {{ selected_item_data.label }}
                      </template>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
              <b-card no-body v-if="selected_item_data.isTitle && selected_item_data.isTitle === 'no'">
                <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                  Yetkiler
                </b-card-header>
                <template v-if="f_controlMyLayerPermission('can_edit_permissions')">
                  <b-form-checkbox-group v-model="selected_item_data.perms" stacked style="border-radius: 5px; border: solid 1px #a0d0bc; padding: 5px;">
                    <template v-if="f_controlMyLayerPermission('can_create_task')">
                      <b-form-checkbox name="can_create_task" value="can_create_task"> Görev Oluşturabilir & Düzenleyebilir</b-form-checkbox>
                    </template>
                    <template v-else>
                      <b-row v-if="selected_item_data.perms && selected_item_data.perms.indexOf('can_create_task') !== -1">
                        <b-col cols="12"><i class="fa fa-check" style="color: green;"></i> Görev Oluşturabilir & Düzenleyebilir</b-col>
                      </b-row>
                      <b-row v-else>
                        <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Görev Oluşturabilir & Düzenleyebilir</b-col>
                      </b-row>
                    </template>
                    <template v-if="f_controlMyLayerPermission('can_give_task')">
                      <b-form-checkbox name="can_give_task" value="can_give_task"> Görevi Atayabilir <small>(Kendisine ve Alt Katmana)</small></b-form-checkbox>
                    </template>
                    <template v-else>
                      <b-row v-if="selected_item_data.perms && selected_item_data.perms.indexOf('can_give_task') !== -1">
                        <b-col cols="12"><i class="fa fa-check" style="color: green;"></i> Görevi Atayabilir <small>(Kendisine ve Alt Katmana)</small></b-col>
                      </b-row>
                      <b-row v-else>
                        <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Görevi Atayabilir <small>(Kendisine ve Alt Katmana)</small></b-col>
                      </b-row>
                    </template>
                    <template v-if="f_controlMyLayerPermission('can_recommend_task')">
                      <b-form-checkbox name="can_recommend_task" value="can_recommend_task"> Görev Önerebilir <small>(Görev Oluşturma Yetkisi Olan Kişiye)</small></b-form-checkbox>
                    </template>
                    <template v-else>
                      <b-row v-if="selected_item_data.perms && selected_item_data.perms.indexOf('can_recommend_task') !== -1">
                        <b-col cols="12"><i class="fa fa-check" style="color: green;"></i> Görev Önerebilir <small>(Görev Oluşturma Yetkisi Olan Kişiye)</small></b-col>
                      </b-row>
                      <b-row v-else>
                        <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Görev Önerebilir <small>(Görev Oluşturma Yetkisi Olan Kişiye)</small></b-col>
                      </b-row>
                    </template>
                    <template v-if="f_controlMyLayerPermission('can_edit_permissions')">
                      <b-form-checkbox name="can_edit_permissions" value="can_edit_permissions"> Yetkileri Düzenleyebilir <small>(Alt Katmana, * Sadece sahip olduğu yetkiler arasından düzenleyebilir)</small></b-form-checkbox>
                    </template>
                    <template v-else>
                      <b-row v-if="selected_item_data.perms && selected_item_data.perms.indexOf('can_edit_permissions') !== -1">
                        <b-col cols="12"><i class="fa fa-check" style="color: green;"></i> Yetkileri Düzenleyebilir <small>(Alt Katmana, * Sadece sahip olduğu yetkiler arasından düzenleyebilir)</small></b-col>
                      </b-row>
                      <b-row v-else>
                        <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Yetkileri Düzenleyebilir <small>(Alt Katmana, * Sadece sahip olduğu yetkiler arasından düzenleyebilir)</small></b-col>
                      </b-row>
                    </template>
                    <template v-if="f_controlMyLayerPermission('can_manage_sub_layer')">
                      <b-form-checkbox name="can_manage_sub_layer" value="can_manage_sub_layer"> Alt Katmanı Yönetebilir</b-form-checkbox>
                    </template>
                    <template v-else>
                      <b-row v-if="selected_item_data.perms && selected_item_data.perms.indexOf('can_manage_sub_layer') !== -1">
                        <b-col cols="12"><i class="fa fa-check" style="color: green;"></i> Alt Katmanı Yönetebilir</b-col>
                      </b-row>
                      <b-row v-else>
                        <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Alt Katmanı Yönetebilir</b-col>
                      </b-row>
                    </template>
                    <template v-if="f_controlMyLayerPermission('can_recommend_new_layer')">
                      <b-form-checkbox name="can_recommend_new_layer" value="can_recommend_new_layer"> Yeni Alt Katman Önerebilir</b-form-checkbox>
                    </template>
                    <template v-else>
                      <b-row v-if="selected_item_data.perms && selected_item_data.perms.indexOf('can_recommend_new_layer') !== -1">
                        <b-col cols="12"><i class="fa fa-check" style="color: green;"></i> Yeni Alt Katman Önerebilir</b-col>
                      </b-row>
                      <b-row v-else>
                        <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Yeni Alt Katman Önerebilir</b-col>
                      </b-row>
                    </template>
                    <template v-if="f_controlMyLayerPermission('can_invite_users')">
                      <b-form-checkbox name="can_invite_users" value="can_invite_users"> Kullanıcı Davet Edebilir</b-form-checkbox>
                    </template>
                    <template v-else>
                      <b-row v-if="selected_item_data.perms && selected_item_data.perms.indexOf('can_invite_users') !== -1">
                        <b-col cols="12"><i class="fa fa-check" style="color: green;"></i> Kullanıcı Davet Edebilir</b-col>
                      </b-row>
                      <b-row v-else>
                        <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Kullanıcı Davet Edebilir</b-col>
                      </b-row>
                    </template>
                    <!--                   <template v-if="f_controlMyLayerPermission('can_view_all_layers_tasks')">
                    <b-form-checkbox name="can_view_all_layers_tasks" value="can_view_all_layers_tasks"> Tüm katmanlara ait görevleri görebilir</b-form-checkbox>
                  </template>
                  <template v-else>
                    <b-row v-if="selected_item_data.perms.indexOf('can_view_all_layers_tasks') !== -1">
                      <b-col cols="12"><i class="fa fa-check" style="color: green;"></i> Tüm katmanlara ait görevleri görebilir</b-col>
                    </b-row>
                    <b-row v-else>
                      <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Tüm katmanlara ait görevleri görebilir</b-col>
                    </b-row>
                  </template>
                  <template v-if="f_controlMyLayerPermission('can_view_selected_and_its_sub_layer_tasks')">
                    <b-form-checkbox name="can_view_selected_and_its_sub_layer_tasks" value="can_view_selected_and_its_sub_layer_tasks"> Seçilen ve altındaki katmanlara ait görevleri görebilir</b-form-checkbox>
                  </template>
                  <template v-else>
                    <b-row v-if="selected_item_data.perms.indexOf('can_view_selected_and_its_sub_layer_tasks') !== -1">
                      <b-col cols="12"><i class="fa fa-check" style="color: green;"></i> Seçilen ve altındaki katmanlara ait görevleri görebilir</b-col>
                    </b-row>
                    <b-row v-else>
                      <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Seçilen ve altındaki katmanlara ait görevleri görebilir</b-col>
                    </b-row>
                  </template>
 -->
                  </b-form-checkbox-group>
                </template>
                <template v-else>
                  <b-row v-if="selected_item_data.perms && selected_item_data.perms.indexOf('can_create_task') !== -1">
                    <b-col cols="12"><i class="fa fa-check" style="color: green;"></i> Görev Oluşturabilir & Düzenleyebilir</b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Görev Oluşturabilir & Düzenleyebilir</b-col>
                  </b-row>
                  <b-row v-if="selected_item_data.perms && selected_item_data.perms.indexOf('can_give_task') !== -1">
                    <b-col cols="12"><i class="fa fa-check" style="color: green;"></i> Görevi Atayabilir <small>(Kendisine ve Alt Katmana)</small></b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Görevi Atayabilir <small>(Kendisine ve Alt Katmana)</small></b-col>
                  </b-row>
                  <b-row v-if="selected_item_data.perms && selected_item_data.perms.indexOf('can_recommend_task') !== -1">
                    <b-col cols="12"><i class="fa fa-check" style="color: green;"></i> Görev Önerebilir <small>(Görev Oluşturma Yetkisi Olan Kişiye)</small></b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Görev Önerebilir <small>(Görev Oluşturma Yetkisi Olan Kişiye)</small></b-col>
                  </b-row>
                  <b-row v-if="selected_item_data.perms && selected_item_data.perms.indexOf('can_edit_permissions') !== -1">
                    <b-col cols="12"><i class="fa fa-check" style="color: green;"></i>Yetkileri Düzenleyebilir <small>(Alt Katmana, * Sadece sahip olduğu yetkiler arasından düzenleyebilir)</small></b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Yetkileri Düzenleyebilir <small>(Alt Katmana, * Sadece sahip olduğu yetkiler arasından düzenleyebilir)</small></b-col>
                  </b-row>
                  <b-row v-if="selected_item_data.perms && selected_item_data.perms.indexOf('can_manage_sub_layer') !== -1">
                    <b-col cols="12"><i class="fa fa-check" style="color: green;"></i> Alt Katmanı Yönetebilir</b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Alt Katmanı Yönetebilir</b-col>
                  </b-row>
                  <b-row v-if="selected_item_data.perms && selected_item_data.perms.indexOf('can_recommend_new_layer') !== -1">
                    <b-col cols="12"><i class="fa fa-check" style="color: green;"></i> Yeni Alt Katman Önerebilir</b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Yeni Alt Katman Önerebilir</b-col>
                  </b-row>
                  <b-row v-if="selected_item_data.perms && selected_item_data.perms.indexOf('can_invite_users') !== -1">
                    <b-col cols="12"><i class="fa fa-check" style="color: green;"></i> Kullanıcı Davet Edebilir</b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Kullanıcı Davet Edebilir</b-col>
                  </b-row>
                  <!--                 <b-row v-if="selected_item_data.perms.indexOf('can_view_all_layers_tasks') !== -1">
                  <b-col cols="12"><i class="fa fa-check" style="color: green;"></i> Tüm katmanlara ait görevleri görebilir</b-col>
                </b-row>
                <b-row v-else>
                  <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Tüm katmanlara ait görevleri görebilir</b-col>
                </b-row>
                <b-row v-if="selected_item_data.perms.indexOf('can_view_selected_and_its_sub_layer_tasks') !== -1">
                  <b-col cols="12"><i class="fa fa-check" style="color: green;"></i> Seçilen ve altındaki katmanlara ait görevleri görebilir</b-col>
                </b-row>
                <b-row v-else>
                  <b-col cols="12"><i class="fa fa-ban" style="color: red;"></i> Seçilen ve altındaki katmanlara ait görevleri görebilir</b-col>
                </b-row> 
                -->
                </template>
              </b-card>
              <b-card no-body v-if="selected_item_data.index">
                <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                  Seçili Katman Hakkında
                </b-card-header>
                <template v-if="f_controlMyLayerPermission('can_manage_sub_layer', true)">
                  <b-textarea v-model="selected_item_data.about" rows="5"></b-textarea>
                </template>
                <template v-else-if="selected_item_data.about">
                  {{ selected_item_data.about }}
                </template>
              </b-card>
            </template>
          </b-card>
        </template>
        <template v-else-if="tree_list_type === 'task_tree_list'">
          <b-card footer-tag="footer" header-tag="header" v-if="selected_items.length > 0" no-body style="height: 100%; color: green;">
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              <i class="fa fa-th-list"></i>
              <span v-if="tree_list_data.tree_detail && tree_list_data.tree_detail.label">{{ tree_list_data.tree_detail.label }}</span>
              <span v-else>Başlık henüz tanımlanmadı</span>
            </b-card-header>
            <b-row style="padding: 5px;">
              <b-col sm="12" lg="12">
                <wisdom-data-show v-if="d_showTaskDetail" :change="data_show_change" :view_mode="'table'" :data="d_taskDetail" :data_type="'wdm17'" :option_data="option_data['wdm17']"></wisdom-data-show>
              </b-col>
            </b-row>
          </b-card>
        </template>
        <template v-else>
          <b-card footer-tag="footer" header-tag="header" v-if="selected_items.length > 0" no-body style="height: 100%; color: green;">
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              <i class="fa fa-th-list"></i>
              <span v-if="tree_list_data.tree_detail && tree_list_data.tree_detail.label">{{ tree_list_data.tree_detail.label }}</span>
              <span v-else>Başlık henüz tanımlanmadı</span>
            </b-card-header>
            <b-row style="padding: 5px;">
              <b-col sm="12" lg="12">
                <b-form-group label="" label-for="group_type" label-cols="3" validated>
                  <template slot="label"> <i class="fa fa-hand-o-right"></i> Grup Başlığı mı? </template>
                  <template>
                    <b-form-select class="form-control-warning" id="group_value" v-model="selected_item_data.isTitle" required>
                      <option value="yes">{{ $t('wdm16.7249') }}</option>
                      <option value="no">{{ $t('wdm16.76') }}</option>
                    </b-form-select>
                    <b-form-valid-feedback></b-form-valid-feedback>
                    <b-form-invalid-feedback>Sadece başlık olarak kullanmak ve grup oluşturmak istiyorsanız evet seçeneğini seçiniz.</b-form-invalid-feedback>
                  </template>
                </b-form-group>
                <b-form-group label="" label-for="group_label" label-cols="3" validated>
                  <template slot="label"> <i class="fa fa-hand-o-right"></i> Başlık </template>
                  <template>
                    <b-form-input type="text" class="form-control-warning" id="group_label" v-model="selected_item_data.label" required></b-form-input>
                    <span v-if="label_alert" style="color: red;"> AYNI DEĞER MEVCUT LÜTFEN DEĞERİNİZİ DÜZELTİNİZ </span>
                    <b-form-valid-feedback></b-form-valid-feedback>
                    <b-form-invalid-feedback>Başlığı giriniz</b-form-invalid-feedback>
                  </template>
                </b-form-group>
                <b-form-group label="" label-for="group_value" label-cols="3" :state="f_checkValueRegex(selected_item_data.value) && !value_alert">
                  <template slot="label"> <i class="fa fa-hand-o-right"></i> Değeri </template>
                  <template>
                    <b-form-input type="text" class="form-control-warning" id="group_label" v-model="selected_item_data.value" :state="f_checkValueRegex(selected_item_data.value) && !value_alert" :formatter="f_formatValueRegex" required></b-form-input>
                    <span v-if="value_alert" style="color: red;"> AYNI DEĞER MEVCUT LÜTFEN DEĞERİNİZİ DÜZELTİNİZ </span>
                    <b-form-valid-feedback></b-form-valid-feedback>
                    <b-form-invalid-feedback>{{ $t('wdm16.12895') }}
                      <br>{{ $t('wdm16.12896') }}
                      <br>{{ $t('wdm16.12897') }}</b-form-invalid-feedback>
                  </template>
                </b-form-group>
                <template v-if="tree_list_data.parent && ['department', 'clinical_branch'].indexOf(tree_list_data.parent) !== -1">
                  <b-form-group label="" label-for="med_branch_id" label-cols="3" validated>
                    <template slot="label"> <i class="fa fa-hand-o-right"></i> HBYS Branş ID</template>
                    <template>
                      <template v-if="!HospitalClinicalBranchResult.status">
                        Hasta klinik branş listesi servisinden herhangi bir bilgi gelmemektedir.
                      </template>
                      <template v-else>
                        <template v-if="HospitalClinicalBranchResult.status === 'error'">
                          Hastane klinik branş listesi hatası mevcut. {{ HospitalClinicalBranchResult }}
                        </template>
                        <template v-else-if="HospitalClinicalBranchResult.status === 'success' && HospitalClinicalBranchResult.result.length === 0">
                          Hasta klinik branş listesi boş olarak gelmektedir.
                        </template>
                        <template v-else-if="HospitalClinicalBranchResult.status === 'success' && HospitalClinicalBranchResult.result.length > 0">
                          <b-form-select id="hospital_branch_id" :plain="true" v-model="selected_item_data.hims_branch_id">
                            <option v-for="(x, x_ind) in HospitalClinicalBranchResult.result" :value="x.id">{{ x.name }}</option>
                          </b-form-select>
                        </template>
                      </template>
                      <b-form-input type="number" class="form-control-warning" id="med_branch_id" v-model="selected_item_data.hims_branch_id" required></b-form-input>
                      <b-form-valid-feedback></b-form-valid-feedback>
                      <b-form-invalid-feedback>HBYS branş ID değerini giriniz</b-form-invalid-feedback>
                    </template>
                  </b-form-group>
                </template>
                <template v-if="tree_list_data.parent && tree_list_data.parent === 'module'">
                  <b-form-group label="" label-for="group_type" label-cols="3" validated>
                    <template slot="label"> <i class="fa fa-hand-o-right"></i> Modül Tipi </template>
                    <template>
                      <b-form-select class="form-control-warning" id="group_value" v-model="selected_item_data.module_type" required>
                        <option value="patient">Hasta</option>
                        <option value="hospital">Hastane</option>
                        <option value="wisdomera">WisdomEra</option>
                      </b-form-select>
                      <b-form-valid-feedback></b-form-valid-feedback>
                      <b-form-invalid-feedback>Modül tipini seçiniz</b-form-invalid-feedback>
                    </template>
                  </b-form-group>
                </template>
                <template v-if="tree_list_data.parent && tree_list_data.parent === 'clinical_branch'">
                  <b-form-group label="" label-for="is_medical_branch" label-cols="3" validated>
                    <template slot="label"> <i class="fa fa-hand-o-right"></i> Tıbbi Branş mı? </template>
                    <template>
                      <b-form-select class="form-control-warning" id="group_value" v-model="selected_item_data.is_medical_branch" required>
                        <option value="yes">{{ $t('wdm16.7249') }}</option>
                        <option value="no">{{ $t('wdm16.76') }}</option>
                      </b-form-select>
                      <b-form-valid-feedback></b-form-valid-feedback>
                      <b-form-invalid-feedback>Sadece başlık olarak kullanmak ve grup oluşturmak istiyorsanız evet seçeneğini seçiniz.</b-form-invalid-feedback>
                    </template>
                  </b-form-group>
                  <b-form-group v-if="selected_item_data.is_medical_branch && selected_item_data.is_medical_branch === 'yes'" label="" label-for="med_branch_id" label-cols="3" validated>
                    <template slot="label"> <i class="fa fa-hand-o-right"></i> Medikal Branş ID</template>
                    <template>
                      <b-form-input type="number" class="form-control-warning" id="med_branch_id" v-model="selected_item_data.med_branch_id" required></b-form-input>
                      <b-form-valid-feedback></b-form-valid-feedback>
                      <b-form-invalid-feedback>Klinik branş ID değerini giriniz</b-form-invalid-feedback>
                    </template>
                  </b-form-group>
                </template>
                <b-form-group label="" label-for="group_about" label-cols="3" validated>
                  <template slot="label"> <i class="fa fa-hand-o-right"></i> Hakkında </template>
                  <template>
                    <b-textarea v-model="selected_item_data.about"></b-textarea>
                  </template>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-col>
    </b-row>
    <modal v-if="show_tree_data_settings" @close="show_tree_data_settings = false">
      <h3 slot="header">Ağaç Verisi Ayarları</h3>
      <div slot="body">
        <b-row style="padding: 3px;">
          <b-col sm="12" md="12" lg="6"> Veri yapısı başlığı </b-col>
          <b-col sm="12" md="12" lg="6">
            <b-form-input type="text" v-model="tree_list_data.name.label"></b-form-input>
          </b-col>
        </b-row>
        <b-row style="padding: 3px;">
          <b-col sm="12" md="12" lg="6"> Veri ağacı başlığı </b-col>
          <b-col sm="12" md="12" lg="6">
            <b-form-input type="text" v-model="tree_list_data.tree_name.label"></b-form-input>
          </b-col>
        </b-row>
        <b-row style="padding: 3px;">
          <b-col sm="12" md="12" lg="6"> Veri detay başlığı </b-col>
          <b-col sm="12" md="12" lg="6">
            <b-form-input type="text" v-model="tree_list_data.tree_detail.label"></b-form-input>
          </b-col>
        </b-row>
        <b-row style="padding: 3px;">
          <b-col sm="12" md="12" lg="6"> Hastane Ayarlarından Yönetim </b-col>
          <b-col sm="12" md="12" lg="6">
            <b-form-select class="form-control-warning" id="group_value" v-model="tree_list_data.edit_in_hospital_settings" required>
              <option value="yes">{{ $t('wdm16.7249') }}</option>
              <option value="no">{{ $t('wdm16.76') }}</option>
            </b-form-select>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <b-button @click="f_saveTreeListData()" variant="success" size="sm" style="margin-right: 3px;"> <i class="fa fa-save"></i> {{ $t('wdm16.3959') }}</b-button>
        <b-button @click="show_tree_data_settings = false" variant="danger" size="sm" style="margin-right: 3px;"> <i class="fa fa-ban"></i> Kapat</b-button>
      </div>
    </modal>
    <modal v-if="show_wisdom_data_modal" @close="show_wisdom_data_modal = false" :width="'750'">
      <h3 slot="header"> Görev Ekleme Ekranı </h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="'wdm17'" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveTask()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="show_wisdom_data_modal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showBrandsFromIngredients" @close="d_showBrandsFromIngredients = false" :width="'1000'">
      <h3 slot="header"> Ürün Seçim Ekranı </h3>
      <div slot="body">
        <b-row>
          <b-col sm="12" md="12">
            <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                Ürün Seçimi Yöntemi Belirleme
              </b-card-header>
              <b-row>
                <b-col sm="12" md="6">
                  <b-form-select v-model="brand_list_type">
                    <option value="selected_brands">Seçili Ürünler</option>
                    <option value="selected_ingredients">Seçili İçeriklere Ait Tüm Ürünler</option>
                  </b-form-select>
                </b-col>
                <b-col sm="12" md="6">
                  <b-button class="pull-right" size="sm" variant="secondary" @click="f_addAllFavoriteIngredients()"><i class="fa fa-plus"></i> Tüm Favori İlaç İçeriklerini Ekle </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6">
            <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                <i class="fa fa-search"></i> İlaç & Malzeme İçerik Sorgulama
              </b-card-header>
              <b-row>
                <b-col cols="12">
                  <b-input-group>
                    <b-form-input id="search_material" placeholder="Ürün adı, içerik adı, barkod no ile arama yapabilirsiniz. Sonuç içerik adıyla listelenir." v-model="d_searchText" type="text" @keydown.enter.native="ClickEnter"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="success" @click="searchMaterial()">ara</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <div style="overflow-y: auto; height: 150px; overflow-x: hidden;">
                <b-table head-variant="dark" striped responsive stacked="sm" bordered borderless small hover :items="d_searchMaterialList" :fields="d_searchMaterialColumns" select-mode="single" selectable selectedVariant="success" @row-dblclicked="rowSelectedSearchMaterial" @row-selected="">
                  <template v-slot:cell(row_no)="row">
                    {{ row.index + 1 }}
                  </template>
                </b-table>
              </div>
            </b-card>
          </b-col>
          <b-col sm="12" md="6">
            <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                <b-row>
                  <b-col sm="12" md="6">
                    <i class="fa fa-search"></i> Ürün Listesi
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-button v-if="brand_list.length > 0" class="pull-right" size="sm" variant="secondary" @click="f_addThisBrandsToSelectedBrands()"><i class="fa fa-plus"></i> Tümünü Ekle</b-button>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-row>
                <b-col cols="12">
                  <b-input-group>
                    <b-form-input id="filter_brand" placeholder="Ürün filtrele" v-model="filter_brand" type="text"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <div style="overflow-y: auto; height: 150px; overflow-x: hidden;">
                <b-table :filter="filter_brand" head-variant="dark" striped responsive stacked="sm" bordered borderless small hover :items="brand_list" :fields="d_brandColumns" select-mode="single" selectable selectedVariant="success" @row-dblclicked="rowSelectThisBrand" @row-selected="">
                  <template v-slot:cell(row_no)="row">
                    {{ row.index + 1 }}
                  </template>
                </b-table>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="brand_list_type === 'selected_brands'">
          <b-col sm="12" md="12">
            <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                <b-row>
                  <b-col sm="12" md="6">
                    <i class="fa fa-search"></i> Eklenen Ürünler
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-button class="pull-right" variant="danger" @click="share_brand_data.selected_brands = []"><i class="fa fa-trash"></i> Tümünü Temizle</b-button>
                  </b-col>
                </b-row>
              </b-card-header>
              <div style="overflow-y: auto; height: 300px; overflow-x: hidden;">
                <b-table head-variant="dark" striped responsive stacked="sm" bordered borderless small hover :items="share_brand_data.selected_brands" :fields="d_brandColumns" select-mode="single" selectable selectedVariant="success" @row-dblclicked="" @row-selected="">
                  <template v-slot:cell(row_no)="row">
                    {{ row.index + 1 }}
                  </template>
                  <template v-slot:cell(options)="row">
                    <b-button variant="danger" @click="share_brand_data.selected_brands.splice(row.index, 1)"><i class="fa fa-trash"></i> </b-button>
                  </template>
                </b-table>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="brand_list_type === 'selected_ingredients'">
          <b-col sm="12" md="12">
            <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                <b-row>
                  <b-col sm="12" md="6">
                    <i class="fa fa-search"></i> Eklenen İçerikler
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-button class="pull-right" variant="danger" @click="share_brand_data.selected_ingredients = []"><i class="fa fa-trash"></i> Tümünü Temizle</b-button>
                  </b-col>
                </b-row>
              </b-card-header>
              <div style="overflow-y: auto; height: 300px; overflow-x: hidden;">
                <b-table head-variant="dark" striped responsive stacked="sm" bordered borderless small hover :items="share_brand_data.selected_ingredients" :fields="d_ingredientColumns" select-mode="single" selectable selectedVariant="success" @row-dblclicked="" @row-selected="">
                  <template v-slot:cell(row_no)="row">
                    {{ row.index + 1 }}
                  </template>
                  <template v-slot:cell(options)="row">
                    <b-button variant="danger" @click="share_brand_data.selected_ingredients.splice(row.index, 1)"><i class="fa fa-trash"></i> </b-button>
                  </template>
                </b-table>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button v-if="brand_list_type === 'selected_ingredients'" type="button" class="btn btn-primary" v-on:click="f_shareUnassignedBrandsToSelectedUsers('only_sub_layers', 'selected_ingredients')">Bu içeriklere ait ürünleri seçili katmanın altına paylaştır</button>
        <button v-if="brand_list_type === 'selected_brands'" type="button" class="btn btn-primary" v-on:click="f_shareUnassignedBrandsToSelectedUsers('only_sub_layers', 'selected_brands')">Bu ürünleri seçili katmanın altına paylaştır</button>
        <button type="button" class="btn btn-danger" @click="d_showBrandsFromIngredients = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showWisdomDataModal3" @close="d_showWisdomDataModal3 = false" :width="'1000'">
      <h3 slot="header"> Ürün Düzenleme Ekranı </h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="'wisdom_data_model_3'" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveWisdomDataModel3()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showWisdomDataModal3 = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showTaskCommentsModal" @close="d_showTaskCommentsModal = false" :width="'750'">
      <h3 slot="header">
        <img src="@/icon/1519119.png" width="20px" style="border-radius: 3px;" />
        Görev Yorumlar Ekranı
      </h3>
      <div slot="body">
        <b-card no-body style="margin-bottom: 1px;">
          <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
            Yorumlar
          </b-card-header>
          <template v-if="d_selectedTaskForComment.data && d_selectedTaskForComment.data.general && d_selectedTaskForComment.data.general.comment && d_selectedTaskForComment.data.general.comment.list && d_selectedTaskForComment.data.general.comment.list.length > 0">
            <template v-for="(com, com_ind) in d_selectedTaskForComment.data.general.comment.list">
              <b-card :key="'d_selectedTaskForComment.data.general.comment.list_' + com_ind" no-body style="margin: 3px; background: #fbffdf;">
                <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                  <img src="@/icon/1519119.png" width="20px" style="border-radius: 3px; float: right;" /> {{ com.who_commented.val.label }} {{ com.comment_datetime.val.label }}
                </b-card-header>
                <b-row>
                  <b-col sm="12" md="1">
                    <template v-if="d_allOrganizationUsers[com.who_commented.val.value] && d_allOrganizationUsers[com.who_commented.val.value].pic">
                      <img :src="d_allOrganizationUsers[com.who_commented.val.value].pic" width="45px" style="border-radius: 3px; margin: 2px;" />
                    </template>
                    <template v-else>
                      <img src="@/icon/9370.png" width="100%" style="border-radius: 3px;" />
                    </template>
                  </b-col>
                  <b-col sm="12" md="11">
                    <div style="margin-top: 5px; border: dotted 1px #4dbd74; height: 80%; border-radius: 10px; padding: 5px;">
                      {{ com.message.val }}
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </template>
        </b-card>
        <b-card no-body>
          <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
            Yeni Yorum Ekle
          </b-card-header>
          <b-row style="margin: 0px;">
            <b-col sm="10" md="10" style="padding: 0px;">
              <b-textarea v-model="d_newTaskComment" placeholder="Lütfen yorumunuzu yazınız."></b-textarea>
            </b-col>
            <b-col sm="2" md="2" style="padding: 0px;">
              <b-button style="width: 100%; height: 100%;" variant="secondary" @click="f_addNewComment()">
                <img src="@/icon/1519119.png" width="20px" style="border-radius: 3px;" /> ekle
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_showTaskCommentsModal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_showOrganizationStats" @close="d_showOrganizationStats = false" :width="'1500'">
      <h3 slot="header"> Organizasyon İstatistik Ekranı </h3>
      <div slot="body">
        <organization-task-stats ref="organization-task-stats" :p_organizationId="tree_list_data.id"></organization-task-stats>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showOrganizationStats = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showInviteUserSelectionModal" @close="d_showInviteUserSelectionModal = false" :width="'1500'">
      <h3 slot="header"> Alıcı Seçim Ekranı </h3>
      <div slot="body">
         <user-selection-modal :p_selectedUserList="d_willInviteUserList" :p_filterData="d_invitableUserListFilterData"></user-selection-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showInviteUserSelectionModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import PatientService from '@/services/patient';
import HospitalService from '@/services/hospital';
import WebService from '@/services/webservice';
import { WisdomRegex } from '@/services/public/functions';
import TreeListService from '@/services/tree_list';
import vSelect from 'vue-select';
import GlobalService from '@/services/global';
import UserService from '@/services/users';
import { ClsWdmrForever, ClsWdmrSave } from "@/services/public/wdm";
import { mapGetters } from 'vuex';
import OrganizationService from '@/services/organization';
import OptionService from '@/services/option';
import moment from 'moment';
import {
  default as Modal
} from '@/components/widgets/Modal';
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import {
  default as OrganizationTaskStats
} from '@/components/widgets/OrganizationTaskStats';
import {
  default as UserSelectionModal
} from '@/components/widgets/UserSelectionModal';
// import {
//   default as TaskList
// } from "@/components/widgets/TaskList";
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
/*
import {
  default as TreeListItem
} from '@/components/widgets/TreeListItem';
*/
import {
  default as TreeLayer
} from '@/components/widgets/TreeLayer';

export default {
  name: 'TreeList',
  components: {
    Modal,
    TaskList: () =>
      import ('@/components/widgets/TaskList'),
    // TaskList,
    // TreeListItem,
    vSelect,
    OrganizationTaskStats,
    UserSelectionModal,
    WisdomDataShow,
    WisdomDataModal,
    TreeLayer
  },
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      device: 'device',
      wdm: 'wdm',
      StoreModal: 'modal'
    })
  },
  props: {
    show_items_on_top: {
      type: Boolean,
      required: false,
      default: true
    },
    show_selected_item_parents: {
      type: Boolean,
      required: false,
      default: false
    },
    eligible_items_list: {
      type: Array,
      required: false
    },
    selected_items_data_list: {
      type: Array,
      required: true
    },
    select_type: {
      type: String,
      required: true
    },
    organization_id: {
      type: String,
      required: false
    },
    task_tree_list_key: {
      type: String,
      required: false
    },
    show_mode: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
    tree_list_type: {
      type: String,
      required: true
    },
    max_height: {
      type: String,
      required: false
    },
    pdt: {
      type: Object,
      required: false
    },
    hospital_data: {
      type: Object,
      required: false
    },
    edit_user: {
      type: String,
      required: false,
      default: ''
    },
    follow_up_location: {
      type: String,
      required: false,
      default: ''
    },
    location: {
      type: String,
      required: false
    },
    hospital_group: {
      type: String,
      required: false
    },
    hospital_id: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      d_selectedAnatomySchemaList: [],
      d_device: { 'width': 0, 'height': 0 },
      d_showTaskDetail: false,
      d_taskDetail: {},
      data_show_change: "0",
      d_watchTaskList: { 'save_organization': 0, 'get_task_list': 0 },
      brand_list_type: 'selected_brands',
      d_searchText: '',
      d_showBrandsFromIngredients: false,
      left_tree_list_show: true,
      data_type: '',
      d_selectedOrganizationTab: 'all_task_list',
      show_wisdom_data_modal: false,
      show_tree_data_settings: false,
      tree_list_data: {},
      selected_items: [],
      lastSearchIndex: '',
      searchIndexList: [],
      search_text: '',
      item_adding_mode: false,
      move_item: [],
      change: { 'x': 0 },
      show_html: '',
      carry_option: [],
      value_alert: false,
      label_alert: false,
      d_regexAlert: false,
      is_group_value_cannot_be_same: true, // grup value değeri aynı olamazsa true olarak kalacak.
      is_group_label_cannot_be_same: true, // grup value değeri aynı olamazsa true olarak kalacak.
      group_value: '',
      tree_list_data_bck_data: {},
      selected_item_index: '',
      selected_index1: 'none',
      selected_index2: 'none',
      selected_index3: 'none',
      selected_level: '',
      selected_group_data: { 'value': '', 'label': '' },
      selected_item_data: { 'value': '', 'label': '' },
      user: {},
      level: 0,
      d_parentList: [],
      HospitalClinicalBranchResult: {},
      d_allUsers: [],
      option_data: {},
      wisdom_data: {},
      d_selectedItemTaskList: [],
      d_taskStateList: [
        { 'label': 'Görev Tamamlandı', 'value': 'completed' },
        { 'label': 'Atandı', 'value': 'assigned' }
      ],
      d_searchTaskListData: {
        'search_text': '',
        'organization_list': [],
        'username': '',
        'organization_id': 'uuid',
        'user_type': 'all', // this
        'priority': [], // drug_medical_equipment
        'task_type_list': ['general', 'drug_medical_equipment'], // drug_medical_equipment
        'state_list': ['not_assigned', 'assigned', 'completed'],
        'organization_type': 'this', // 'this' organization, 'all' organization
        'pagination': { 'start': 0, 'end': 50, 'current': 1, 'perpage': 50 },
        'tree_filter': ['with_tree', 'with_tree_main', 'with_tree_sub', 'without_tree']
      },
      d_pagination: {
        'start': 0,
        'end': 50,
        'current': 1,
        'perpage': 50
      },
      d_taskList: [],
      d_taskListCount: 0,
      d_selectedItemTaskListCount: 0,
      my_item_index_list: [],
      d_wdm17StateOptions: [],
      d_newTaskUserType: 'user',
      d_organizationHead: '',
      d_userListWithDepartments: [],
      d_selectedTaskForComment: {},
      d_newTaskComment: '',
      d_showTaskCommentsModal: false,
      d_allOrganizationUsers: {},
      d_allOrganizationUserPictureKeyList: [],
      d_taskSaveMode: 'new_task',
      d_showWisdomDataModal3: false,
      d_wdm17TaskTypeOptions: [],
      d_drugMedicalEquipmentTaskList: { 'list': [], 'count': 0 },
      not_assigned_brand_count: 0,
      share_brand_data: {
        'note': 'Ürün bilgileri düzenlenecek',
        'priority': { 'label': 'acil', 'value': 'urgent' },
        'user_list': [],
        'organization_id': '',
        'target': 500,
        'selected_ingredients': [],
        'selected_brands': [],
        'priority': '',
        'note': ''
      },
      d_priorityList: [],
      selected_task_list_type: '',
      d_canGetTaskListWithWatch: false,
      selected_task_index: '',
      d_searchMaterialList: [],
      d_searchMaterialColumns: [{
        key: "label",
        label: "İçerik Adı"
      }],
      d_brandColumns: [{
        key: "options",
        label: ""
      }, {
        key: "barcode_no",
        label: "Barkod No"
      }, {
        key: "brand_name",
        label: "Ürün Adı"
      }],
      d_ingredientColumns: [{
        key: "options",
        label: ""
      }, {
        key: "label",
        label: "İçerik"
      }],
      d_selectedIngredientData: {},
      brand_list: [],
      filter_brand: '',
      d_patientDataProjectList: [],
      d_projectPatientList: {},
      d_selectedPatientDataProject: {},
      d_patientListTableData: { 'current': 1, 'per-page': 50 },
      searchPatientColumns: [{
        key: "actions",
        label: ""
      }, {
        key: "rowNumber",
        label: " "
      }, {
        key: "record_id",
        label: "WisdomEra Hasta No",
        sortable: true
      }, {
        key: "name",
        label: "Hasta Adı",
        sortable: true
      }, {
        key: "age",
        label: "yaş",
        sortable: true
      }, {
        key: "sex",
        label: "Cinsiyet",
        sortable: true
      }, {
        key: "personel_number",
        label: "Tc No",
        sortable: true
      }, {
        key: "passport_number",
        label: "Pasaport No"
      }, {
        key: "himsPatientID",
        label: "Medin Hasta No"
      }, {
        key: "citizen_type",
        label: "vatandaşlık",
        sortable: true
      }, {
        key: "preassigned_username",
        label: "Ön tanımlı kullanıcı",
        sortable: true
      }],
      d_hospitalGroup: '',
      d_hospitalId: '',
      d_patientListType: 'project',
      d_hospitalGroupList: [],
      d_hospitalList: [],
      d_searchText: '',
      d_showOrganizationStats: false,
      d_showInviteUserSelectionModal: false,
      d_invitationData: { 'invite_text': '', 'invited_username_list': [], 'organization_id': '' },
      d_willInviteUserList: [],
      d_invitableUserListFilterData: {'active_status': 'active', 'forbidden_perms': [], 'forbidden_usernames': [], 'needed_perms': [], 'needed_perm_type': 'or'}
    }
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    let location = 'wisdom';
    if (this.location) {
      location = this.location;
    }
    let hospital_group = this.hospital_group ? this.hospital_group : '';
    let hospital_code = this.hospital_id ? this.hospital_id : '';
    let query = 'data_type=' + this.tree_list_type + '&location=' + location + '&hospital_group=' + hospital_group + '&hospital_code=' + hospital_code;
    if (this.tree_list_type === 'module_and_model') {
      this.tree_list_data = JSON.parse(localStorage.getItem('modules'));
      this.tree_list_data_bck_data = JSON.parse(JSON.stringify(this.tree_list_data));
      this.f_createIndexForAllItems(this.tree_list_data.list, { 'item_index': [] });
      this.f_selectSelectedItemsDataList();
      this.f_showSelectedItemsParents();
    } else if (this.tree_list_type === 'organization') {
      let all_users = JSON.parse(localStorage.getItem('all_users'));
      for (let i in all_users) {
        this.d_allUsers.push({ 'label': all_users[i].first_name + ' ' + all_users[i].last_name, 'value': all_users[i].username });
      }
      if (this.$route.query.organization_id) {
        this.organization_id = this.$route.query.organization_id;
      }
      query = 'data_type=wdm17&location=wisdom';
      OptionService.get_last_wdm_of_this_type(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.option_data['wdm17'] = resp.data.result;
            this.f_getUserListWithDepartments();
            try {
              this.d_wdm17StateOptions = this.option_data['wdm17'].general.parameters.status.options;
            } catch (err) {
              console.log(err);
              this.d_wdm17StateOptions = [];
            }
            try {
              this.d_wdm17TaskTypeOptions = this.option_data['wdm17'].general.parameters.task_type.options;
            } catch (err) {
              console.log(err);
              this.d_wdm17TaskTypeOptions = [];
            }
            try {
              this.d_priorityList = this.option_data['wdm17'].general.parameters.priority.options;
              for (let i in this.d_priorityList) {
                this.d_searchTaskListData.priority.push(this.d_priorityList[i].value);
              }
            } catch (err) {
              console.log(err);
              this.d_priorityList = [];
            }
            this.d_canGetTaskListWithWatch = true;
            GlobalService.get_one_cb_document({ 'key': 'organization_' + this.organization_id, 'bucket': 'default' })
              .then(resp => {
                // console.log(resp.data.result);
                if (resp.data.status === 'success') {
                  this.tree_list_data = resp.data.result;
                  this.tree_list_data_bck_data = JSON.parse(JSON.stringify(this.tree_list_data));
                  this.f_createIndexForAllItems(this.tree_list_data.list, { 'item_index': [] });
                  this.f_getUserIndexList(this.tree_list_data.list, this.user.username, this.my_item_index_list);
                  this.f_getUsersPictures();
                  // this.f_getTaskList([], 'all_task_list');
                } else {
                  alert('Organizasyon döküman hatası: ' + resp.data.message);
                }
              });
          } else {
            console.log('errror ', resp.data.message);
          }
        });
      query = 'data_type=wisdom_data_model_3&location=wisdom';
      OptionService.get_last_wdm_of_this_type(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.option_data['wisdom_data_model_3'] = resp.data.result;
          } else {
            console.log('errror ', resp.data.message);
          }
        });
    } else if (this.tree_list_type === 'task_tree_list') {
      let query = 'data_type=wdm17&location=wisdom';
      OptionService.get_last_wdm_of_this_type(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.option_data['wdm17'] = resp.data.result;
          } else {
            console.log('errror ', resp.data.message);
          }
        });
      GlobalService.get_one_cb_document({ 'bucket': 'default', 'key': this.task_tree_list_key })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.tree_list_data = resp.data.result;
            this.tree_list_data_bck_data = JSON.parse(JSON.stringify(this.tree_list_data));
          } else {
            this.tree_list_data = {};
            alert('Görev ağacı verisine ulaşılamadı');
          }
        });
    } else {
      TreeListService.getTreeList(query)
        .then(resp => {
          // console.log(resp.data);
          if (resp.data.status === 'success') {
            this.tree_list_data = resp.data.result;
            this.tree_list_data_bck_data = JSON.parse(JSON.stringify(this.tree_list_data));
          } else {
            this.tree_list_data = {};
          }
          // Burası datanın tipini belirleyen bölge
          if (!this.tree_list_data.parent) {
            this.tree_list_data.parent = this.tree_list_type;
          }
          if (!this.tree_list_data.type) {
            this.tree_list_data.type = this.tree_list_type + '_tree';
          }
          //
          if (this.tree_list_data.name === undefined) {
            this.tree_list_data.name = { 'label': '', 'translation': { 'tr': '', 'en': '' } };
          }
          if (this.tree_list_data.tree_name === undefined) {
            this.tree_list_data.tree_name = { 'label': '', 'translation': { 'tr': '', 'en': '' } };
          }
          if (this.tree_list_data.tree_detail === undefined) {
            this.tree_list_data.tree_detail = { 'label': '', 'translation': { 'tr': '', 'en': '' } };
          }
          if (this.tree_list_data.list === undefined) {
            this.tree_list_data.list = [];
          }
          this.f_selectSelectedItemsDataList();
          this.f_showSelectedItemsParents();
          if (['clinical_branch', 'department'].indexOf(this.tree_list_data.parent) !== -1) {
            this.GetClinicalBranchesOfHospital();
          }
          this.f_createIndexForAllItems(this.tree_list_data.list, { 'item_index': [] });
        });
    }
  },
  mounted: function () {
    this.d_device.width = window.innerWidth;
    this.d_device.height = window.innerHeight;
  },
  methods: {
    f_showInviteUserSelectionModal: function () {
      GlobalService.get_one_cb_document({ 'key': 'organization_' + this.tree_list_data.id + '_users', 'bucket': 'default' })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_invitableUserListFilterData = {'active_status': 'active', 'forbidden_perms': [], 'forbidden_usernames': [], 'needed_perms': [], 'needed_perm_type': 'or', 'info': {'invited': {'value': 'invited', 'icon': 'fa fa-envelope', 'variant': 'success', 'label': 'Davet Edilmiş', 'list': []}}};
            let org_users_doc = resp.data.result;
            for (let i in org_users_doc.users) {
              if (this.d_invitableUserListFilterData.forbidden_usernames.indexOf(org_users_doc.users[i].username) === -1) {
                this.d_invitableUserListFilterData.forbidden_usernames.push(org_users_doc.users[i].username);
              }
            }
            if (org_users_doc.invitation && org_users_doc.invitation.decline) {
              for (let declined_user in org_users_doc.invitation.decline) {
                if (this.d_invitableUserListFilterData.forbidden_usernames.indexOf(declined_user) === -1) {
                  this.d_invitableUserListFilterData.forbidden_usernames.push(declined_user);
                }
              }
            }
            if (org_users_doc.invitation && org_users_doc.invitation.wait) {
              for (let wait_user in org_users_doc.invitation.wait) {
                if (this.d_invitableUserListFilterData.info.invited.list.indexOf(wait_user) === -1) {
                  this.d_invitableUserListFilterData.info.invited.list.push(wait_user);
                }
              }
            }
            this.d_willInviteUserList = [];
            this.d_showInviteUserSelectionModal = true;
            
          } else if (resp.data.message === 'error') {
            alert('error ', resp.data.message);
          }
        });
    },
    f_sendOrganizationInvites: function () {
      this.d_invitationData.organization_id = this.tree_list_data.id;
      for (let i in this.d_willInviteUserList) {
        if (this.d_invitationData.invited_username_list.indexOf(this.d_willInviteUserList[i].username) === -1) {
          this.d_invitationData.invited_username_list.push(this.d_willInviteUserList[i].username);
        }
      }
      OrganizationService.invite_to_organization(this.d_invitationData)
        .then(resp => {
          alert('tamamdir');
        });
    },
    f_sendEmailToOrganizationUsers: function () {
      let data = {};
      data.organization_id = this.tree_list_data.id;
      if (this.selected_item_data && this.selected_item_data.index) {
        data.begin_index = this.selected_item_data.index;
        data.include_begin_index = 1;
      }
      /* Ex:
      data = {
        'organization_id': 'uuid'
      }
      or
      data = {
        'organization_id': 'uuid',
        'begin_index': '0,0,1'
      }
      */
      OrganizationService.send_email_to_organization_users(data)
        .then(resp => {
          alert('tamamdir');
        });
    },
    f_showOrganizationStats: function () {
      this.d_showOrganizationStats = true;
    },
    f_assignNotAssignedProjectPatients: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hastalar dağıtılıyor' } });
      let list_user_obj = {};
      this.f_getUsersUnderTheSelectedSubLayer(this.selected_item_data.list, list_user_obj, 'object');
      OrganizationService.assign_not_assigned_data_project_patients({ 'organization_id': this.tree_list_data.id, 'patient_data_project_key': 'patient_data_project_' + this.d_selectedPatientDataProject.value, 'user_list': list_user_obj })
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        });
    },
    f_getPatientDataProjectList: function () {
      OrganizationService.get_patient_data_project_list()
        .then(resp => {
          this.d_patientDataProjectList = resp.data.result;
        });
    },
    DateFormatWithTime: function (date) {
      return moment(date, 'YYYY-MM-DDThh:mm:ss').format('DD/MM/YYYY hh:mm');
    },
    f_calculateLastAssignedDate: function (task) {
      try {
        for (let i = task.data.general.log.list.length - 1; i >= 0; i--) {
          if (task.data.general.log.list[i].log_status.val.value === 'assigned') {
            let k = this.DateFormatWithTime(task.data.general.log.list[i].log_datetime.val);
            if (k) {
              return k;
            } else {
              return '';
            }
          }
        }
      } catch (err) {}
      return '';
    },
    f_calculatePriorityStyle: function (task) {
      let task_priority = '';
      try {
        task_priority = task.data.general.priority.val.value;
      } catch (err) {
        task_priority = '';
      }
      if (task_priority === 'urgent') {
        return 'float: left; background: red; height: 100%; width: 12px; margin-right: 3px; border-radius: 7px; box-shadow: -2px -2px 10px;';
      } else if (task_priority === 'high') {
        return 'float: left; background: #ed6e40; height: 100%; width: 12px; margin-right: 3px; border-radius: 7px; box-shadow: -2px -2px 10px;';
      } else if (task_priority === 'medium') {
        return 'float: left; background: #f1ae54; height: 100%; width: 12px; margin-right: 3px; border-radius: 7px; box-shadow: -2px -2px 10px;';
      } else if (task_priority === 'low') {
        return 'float: left; background: #f7cd46; height: 100%; width: 12px; margin-right: 3px; border-radius: 7px; box-shadow: -2px -2px 10px;';
      }
    },
    f_addAllFavoriteIngredients: function () {
      if (this.brand_list_type === 'selected_ingredients') {
        let favorite_drugs = require('@/options/favorite_drugs').options;
        for (let d in favorite_drugs) {
          this.share_brand_data.selected_ingredients.push(favorite_drugs[d]);
        }
      } else {
        alert('Lütfen önce içerikten ürün seçimi tipini seçiniz.');
      }
    },
    f_addThisBrandsToSelectedBrands: function () {
      for (let i in this.brand_list) {
        let det = false;
        for (let b in this.share_brand_data.selected_brands) {
          if (this.brand_list[i].barcode_no === this.share_brand_data.selected_brands[b].barcode_no) {
            det = true;
            break;
          }
        }
        if (!det) {
          this.share_brand_data.selected_brands.push(JSON.parse(JSON.stringify(this.brand_list[i])));
        }
      }
    },
    rowSelectThisBrand: function (row) {
      let det = false;
      for (let i in this.share_brand_data.selected_brands) {
        if (this.share_brand_data.selected_brands[i].barcode_no === row.barcode_no) {
          det = true;
          break;
        }
      }
      if (!det) {
        this.share_brand_data.selected_brands.push(row);
      } else {
        alert('Seçtiğiniz ürün zaten eklidir.');
      }
    },
    rowSelectedSearchMaterial: function (row) {
      this.brand_list = [];
      this.d_selectedIngredientData = '';
      GlobalService.get_one_cb_document({ 'key': row.key, 'bucket': 'option' })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_selectedIngredientData = resp.data.result;
            if (this.d_selectedIngredientData && this.d_selectedIngredientData.data.general && this.d_selectedIngredientData.data.general.brand_list && this.d_selectedIngredientData.data.general.brand_list.list && this.d_selectedIngredientData.data.general.brand_list.list.length > 0) {
              for (let i in this.d_selectedIngredientData.data.general.brand_list.list) {
                let x = {
                  'barcode_no': this.d_selectedIngredientData.data.general.brand_list.list[i].barcode_no.val,
                  'brand_name': this.d_selectedIngredientData.data.general.brand_list.list[i].brand_name.val
                };
                this.brand_list.push(x);
              }
            }
          } else if (resp.data.message === 'error') {
            alert('error ', resp.data.message);
          }
        });
    },
    ClickEnter: function () {
      this.searchMaterial();
    },
    searchMaterial: function () {
      let data = { 'search': this.d_searchText, 'wdm_type': 'wdm2' };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İlaç listesi hazırlanıyor. Lütfen bekleyiniz.' } });
      OptionService.search_wdm_option(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_searchMaterialList = resp.data.result;
          } else {
            this.d_searchMaterialList = [];
          }
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        })
    },
    f_prepareBrandsFromIngredients: function () {
      this.brand_list_type = 'selected_brands';
      this.d_showBrandsFromIngredients = true;
    },
    f_calculateCountOfSeenComments: function (task) {
      if (task.data && task.data.general && task.data.general.comment && task.data.general.comment.list) {
        let count = 0;
        for (let c in task.data.general.comment.list) {
          if (task.data.general.comment.list[c].who_saw && task.data.general.comment.list[c].who_saw.list) {
            for (let w in task.data.general.comment.list[c].who_saw.list) {
              if (task.data.general.comment.list[c].who_saw.list[w].user_saw.val && task.data.general.comment.list[c].who_saw.list[w].user_saw.val.value === this.user.username) {
                count += 1;
              }
            }
          }
        }
        return count.toString() + ' / ' + task.data.general.comment.list.length.toString();
      } else {
        return '0 / 0';
      }
    },
    f_showTreeListStyleRight: function () {
      if (this.left_tree_list_show) {
        return '7';
      } else {
        return '12';
      }
    },
    f_showTreeListStyleLeft: function () {
      if (this.mode === 'edit') {
        if (this.left_tree_list_show) {
          return '5';
        }
      } else {
        return '12';
      }
    },
    f_controlState: function (type, status, task_data) {
      let task_status = task_data.data.general.status.val.value;
      // if the same status, return false
      if (task_status === status) {
        return false;
      }
      if (['assigned', 'recommended'].indexOf(status) !== -1) {
        return false;
      }
      if (['not_assigned'].indexOf(status) !== -1 && type === 'all_task_list') {
        return false;
      }
      // if user did not assign to this task, return false
      if (['refused', 'accepted', 'started', 'pending', 'completed'].indexOf(status) !== -1) {
        if (!task_data.index_list) {
          // not assigned to any user
          return false;
        } else {
          let det = false;
          for (let i in task_data.index_list) {
            for (let k in this.my_item_index_list) {
              if (task_data.index_list[i] === this.my_item_index_list[k]) {
                // this task has been assigned to active user
                det = true;
                break;
              }
            }
            if (det) {
              break;
            }
          }
          if (!det) {
            return false;
          } else {
            // here controlling the task data status and button status relationship
            if (['assigned'].indexOf(task_status) !== -1 && ['pending'].indexOf(status) !== -1) {
              return false;
            }
            if (['started'].indexOf(task_status) === -1 && ['completed'].indexOf(status) !== -1) {
              return false;
            }
          }
        }
      }
      return true;
    },
    f_deleteTasksInOrganization: function (delete_selected_layer) {
      if (this.selected_item_data.index) {
        let data = {
          'delete_selected_layer': delete_selected_layer,
          'layer_index': this.selected_item_data.index,
          'organization_id': this.tree_list_data.id
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Belirlediğiniz katmanın altındaki görevler temizleniyor. Lütfen bekleyiniz.' } });
        OrganizationService.delete_tasks_in_organization(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = ''
            if (resp.data.status === "success") {
              this.tree_list_data = resp.data.result.organization_document;
              msg = resp.data.result.count.toString() + ' adet görev başarıyla temizlendi';
              if (this.d_selectedOrganizationTab === 'drug_medical_equipment') {
                this.f_getNotAssignedBrandCount();
              }
            } else {
              msg = resp.data.message;
            }
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Görev temizleme işlemi hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
            this.$forceUpdate();
          });
      } else {
        alert('lütfen katman seçimi yapınız.');
      }
    },
    f_shareUnassignedBrandsToSelectedUsers: function (type = 'selected_and_sub_layers', brand_list_type) {
      if (this.selected_item_data.index) {
        if (brand_list_type === 'selected_brands' && this.share_brand_data.selected_brands.length === 0) {
          let msg = 'Lütfen ürün seçimi yapınız.';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Ürün paylaştırma işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
        if (brand_list_type === 'selected_ingredients' && this.share_brand_data.selected_ingredients.length === 0) {
          let msg = 'Lütfen içerik seçimi yapınız.';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Ürün paylaştırma işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
        if (!this.share_brand_data.priority) {
          let msg = 'Lütfen öncelik seçimi yapınız.';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Ürün paylaştırma işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
        if (!this.share_brand_data.note) {
          let msg = 'Lütfen görev açıklamasını yazınız.';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Ürün paylaştırma işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
        this.share_brand_data.organization_id = this.tree_list_data.id;
        this.f_getUsersUnderTheSelectedSubLayer(this.selected_item_data.list, this.share_brand_data.user_list);
        if (this.share_brand_data.user_list.length > 0) {
          this.share_brand_data.brand_list_type = brand_list_type;
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Ürün görevleri atama işlemi başlatıldı. Lütfen bekleyiniz.' } });
          OrganizationService.share_unassigned_brands_to_selected_users(this.share_brand_data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              let msg = ''
              if (resp.data.status === "success") {
                this.tree_list_data = resp.data.result.organization_document;
                this.not_assigned_brand_count = resp.data.result.not_assigned_count;
                msg = 'Başarıyla paylaştırıldı';
              } else {
                msg = resp.data.message;
              }
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
              this.$forceUpdate();
            });
        } else {
          let msg = 'Seçtiğiniz katmanın altında kullanıcı bulunmamaktadır.';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Ürün paylaştırma işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
      } else {
        let msg = 'Henüz bir katman seçmediniz. Lütfen önce bir katman seçiniz.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Ürün paylaştırma işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_getUsersUnderTheSelectedSubLayer: function (list_data, user_list, list_type = 'array') {
      for (let i in list_data) {
        if (list_type === 'array') {
          if (list_data[i].isTitle === 'no' && list_data[i].user) {
            user_list.push({ 'label': list_data[i].label, 'value': list_data[i].value, 'index': list_data[i].index });
          }
        } else if (list_type === 'object') {
          user_list[list_data[i].value] = { 'label': list_data[i].label, 'value': list_data[i].value, 'index': list_data[i].index };
        }
        if (list_data[i].list && list_data[i].list.length > 0) {
          this.f_getUsersUnderTheSelectedSubLayer(list_data[i].list, user_list, list_type);
        }
      }
    },
    /*
    f_getDrugMedicalEquipmentAsTask: function () {
      OrganizationService.get_drug_medical_equipment_as_task()
        .then(resp => {
          let msg = ''
          if (resp.data.status === "success") {
            this.d_drugMedicalEquipmentTaskList.list = resp.data.result.list;
            this.d_drugMedicalEquipmentTaskList.count = resp.data.result.count;
          } else {
            msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        });
    },
    */
    f_saveWisdomDataModel3: function () {
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data['wisdom_data_model_3'], ['name', 'delete']);
      let wdmr_name = return_data.name;
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      let barcode_no = this.wisdom_data['data']['1000']['barcode_number']['val'];
      let data = { 'barcode_no': barcode_no, 'brand_data': this.wisdom_data };
      OptionService.save_wdm_brand_details(data)
        .then(resp => {
          let msg = ''
          if (resp.data.status === "success") {
            msg = 'Başarıyla kaydedildi';
            this.d_showWisdomDataModal3 = false;
          } else {
            msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        });
    },
    f_editBrand: function (task) {
      let barcode_no = '';
      let brand_name = '';
      let ingredient_value = '';
      let ingredient_name = '';
      if (task.data && task.data.general && task.data.general.drug_medical_equipment && task.data.general.drug_medical_equipment.val && task.data.general.drug_medical_equipment.val.value) {
        barcode_no = task.data.general.drug_medical_equipment.val.value;
        brand_name = task.data.general.drug_medical_equipment.val.label;
        ingredient_value = task.data.general.drug_medical_equipment.val.ingredient_value;
        ingredient_name = task.data.general.drug_medical_equipment.val.ingredient_name;
      }
      if (barcode_no) {
        GlobalService.get_one_cb_document({ 'key': 'wisdom_data_model_3_' + barcode_no, 'bucket': 'option' })
          .then(resp => {
            if (resp.data.status === 'success') {
              if (resp.data.result.id) {
                this.wisdom_data = resp.data.result;
                for (let i in this.option_data['wisdom_data_model_3'].param_group.param_order) {
                  if (!this.wisdom_data['data'][this.option_data['wisdom_data_model_3'].param_group.param_order[i]]) {
                    this.wisdom_data['data'][this.option_data['wisdom_data_model_3'].param_group.param_order[i]] = {};
                  }
                }
              } else {
                let pre_data = {
                  'id': 'new',
                  'data': {},
                  'type': 'wisdom_data_model_3',
                  'reference_wdm': {},
                  'parent': 'wisdom_data_model_3'
                };
                pre_data['reference_wdm']['version'] = this.option_data['wisdom_data_model_3']['version'];
                pre_data['reference_wdm']['owner_type'] = this.option_data['wisdom_data_model_3']['owner_type'];
                pre_data['reference_wdm']['key'] = 'wdm_wisdom_wisdom_data_model_3_v' + this.option_data['wisdom_data_model_3'].version;
                for (let i in this.option_data['wisdom_data_model_3'].param_group.param_order) {
                  pre_data['data'][this.option_data['wisdom_data_model_3'].param_group.param_order[i]] = {};
                }
                pre_data['related_wdm_key'] = 'wdm2_' + ingredient_value;
                pre_data['data']['1000']['ingredient_value'] = { 'val': '' };
                pre_data['data']['1000']['ingredient_name'] = { 'val': '' };
                pre_data['data']['1000']['barcode_number'] = { 'val': '' };
                pre_data['data']['1000']['brand_name'] = { 'val': '' };
                pre_data['data']['1000']['barcode_number']['val'] = barcode_no;
                pre_data['data']['1000']['brand_name']['val'] = brand_name;
                pre_data['data']['1000']['ingredient_value']['val'] = ingredient_value;
                pre_data['data']['1000']['ingredient_name']['val'] = ingredient_name;
                this.wisdom_data = pre_data;
              }
              this.d_showWisdomDataModal3 = true;
            } else {
              alert('Error : ' + resp.data.message);
            }
          });
      } else {
        alert('Ürün seçimi yapılmamış');
      }
    },
    f_getIndexListAsString: function (index_list) {
      let str = '';
      for (let i in index_list) {
        let x = index_list[i].split(',');
        for (let k in x) {
          str += (parseInt(x[k]) + 1).toString();
          str += '.';
        }
        if (parseInt(i) !== index_list.length - 1) {
          str += ' - ';
        }
      }
      return str;
    },
    f_getUsersPictures: function () {
      GlobalService.get_multiple_cb_documents({ 'key_list': this.d_allOrganizationUserPictureKeyList, 'bucket': 'uploads' })
        .then(resp => {
          if (resp.data.status === 'success') {
            for (let i in resp.data.result) {
              this.d_allOrganizationUsers[resp.data.result[i].username].pic = resp.data.result[i].picture;
            }
          } else {
            console.log('error:  ' + resp.data.message);
          }
        });
    },
    f_addNewComment: function () {
      if (!this.d_newTaskComment) {
        alert('Lütfen yorum alanına birşeyler yazınız.');
        return;
      }
      if (!this.d_selectedTaskForComment.data.general.comment) {
        this.d_selectedTaskForComment.data.general.comment = { 'list': [] };
      }
      let x = {
        'who_saw': {
          'list': []
        },
        'message': {
          'val': this.d_newTaskComment
        },
        'comment_datetime': {
          'val': 'new'
        },
        'who_commented': {
          'val': {
            'label': this.user.first_name + ' ' + this.user.last_name,
            'value': this.user.username
          }
        }
      };
      this.d_selectedTaskForComment.data.general.comment.list.push(JSON.parse(JSON.stringify(x)));
      let prepare_seen_data = {
        'user_saw': {
          'val': {
            'value': this.user.username,
            'label': this.user.first_name + ' ' + this.user.last_name
          }
        },
        'seen_datetime': {
          'val': ''
        }
      };
      this.d_selectedTaskForComment.data.general.comment.list[this.d_selectedTaskForComment.data.general.comment.list.length - 1].who_saw.list.push(prepare_seen_data);
      OrganizationService.save_task(this.d_selectedTaskForComment)
        .then(resp => {
          if (resp.data.status === 'success') {
            if (this.selected_task_list_type === 'all_task_list') {
              this.d_taskList[this.selected_task_index] = resp.data.result;
            } else {
              this.d_selectedItemTaskList[this.selected_task_index] = resp.data.result;
            }
          } else {
            alert('error ' + resp.data.message);
          }
          this.$forceUpdate();
        });
    },
    f_showComments: function (task_ind, type) {
      this.selected_task_list_type = type;
      this.selected_task_index = task_ind;
      if (type === 'selected_item') {
        this.d_selectedTaskForComment = this.d_selectedItemTaskList[task_ind];
      } else if (type === 'all_task_list') {
        this.d_selectedTaskForComment = this.d_taskList[task_ind];
      }
      let task = this.d_selectedTaskForComment;
      let task_updated = false;
      if (task.data && task.data.general && task.data.general.comment && task.data.general.comment.list) {
        for (let c in task.data.general.comment.list) {
          let comment_seen = false;
          if (task.data.general.comment.list[c].who_saw && task.data.general.comment.list[c].who_saw.list) {
            for (let w in task.data.general.comment.list[c].who_saw.list) {
              if (task.data.general.comment.list[c].who_saw.list[w].user_saw.val && task.data.general.comment.list[c].who_saw.list[w].user_saw.val.value === this.user.username) {
                comment_seen = true;
              }
            }
          }
          if (!comment_seen) {
            task_updated = true;
            if (!task.data.general.comment.list[c].who_saw) {
              task.data.general.comment.list[c].who_saw = { 'list': [] };
            }
            let prepare_seen_data = {
              'user_saw': {
                'val': {
                  'value': this.user.username,
                  'label': this.user.first_name + ' ' + this.user.last_name
                }
              },
              'seen_datetime': {
                'val': ''
              }
            }
            task.data.general.comment.list[c].who_saw.list.push(prepare_seen_data);
          }
        }
      }
      if (task_updated) {
        OrganizationService.save_task(this.d_selectedTaskForComment)
          .then(resp => {
            if (resp.data.status === 'success') {
              if (type === 'all_task_list') {
                this.d_taskList[task_ind] = resp.data.result;
              } else {
                this.d_selectedItemTaskList[task_ind] = resp.data.result;
              }
            } else {
              alert('error ' + resp.data.message);
            }
          });
      }
      this.d_newTaskComment = '';
      this.d_showTaskCommentsModal = true;
    },
    f_getUserListWithDepartments: function () {
      let query = "is_active=1"
      UserService.get_all_users_with_departments(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_userListWithDepartments = resp.data.result;
          } else {
            console.log(resp.data.message);
          }
        });
    },
    f_isActiveUserEqualOrUpperOfTaskLayerList: function (task, can_use_me = false, team_member = false) {
      let task_layer_list = task.index_list;
      if (!task_layer_list || (task_layer_list && task_layer_list.length === 0)) {
        try {
          if (task.created_by === this.user.username && task.data.general.status.val.value === 'not_assigned') {
            return true;
          }
        } catch (err) {
          return false;
        }
        return false;
      }
      if (this.my_item_index_list.length === 0) {
        return false;
      }
      for (let i in this.my_item_index_list) {
        let one_back_index = '';
        if (team_member) {
          let arr = this.my_item_index_list[i].split(',');
          arr.splice(arr.length - 1, 1);
          one_back_index = arr.join(',');
        }
        for (let k in task_layer_list) {
          let layer_index = task_layer_list[i];
          if (layer_index.indexOf(this.my_item_index_list[i]) === 0 || (team_member && layer_index.indexOf(one_back_index) === 0)) {
            if (!can_use_me) {
              if (this.my_item_index_list[i] !== layer_index) {
                return true;
              }
            } else {
              return true;
            }
          }
        }
      }
      return false;
    },
    f_isVisibleForActiveUser: function (task_data) {
      let task_visibility = false;
      try {
        task_visibility = task_data.data.general.task_visibility.val.value;
      } catch (err) {
        task_visibility = false;
      }
      if (!task_visibility) {
        return false;
      }
      // console.log('task_visibility: ' + task_visibility);
      if (task_visibility === 'everybody') {
        return true;
      } else if (task_visibility === 'added_layer_and_upper_layers') {
        let is_active_user_equal_or_upper = this.f_isMyLayerUpperThanOrEqualToSelected(true);
        // console.log('is_active_user_equal_or_upper: ' + is_active_user_equal_or_upper);
        if (is_active_user_equal_or_upper) {
          return true;
        } else {
          return false;
        }
      } else if (task_visibility === 'added_layer_and_upper_layers_and_team_members') {
        let is_active_user_equal_or_upper_or_team_member = this.f_isMyLayerUpperThanOrEqualToSelected(true, true);
        if (is_active_user_equal_or_upper_or_team_member) {
          return true;
        } else {
          return false;
        }
      }
    },
    f_isMyLayerUpperThanOrEqualToSelected: function (can_use_me = false, team_member = false) {
      // aktif kullanıcının my_item_index_listi oluşturulduğundan bu kullanıcının hem yetkisi hem de amir olup olmadığı kontrol ediliyor.
      // can_use_me yani ben kendimin de amiri olarak kabul edileyim mi manasında kullanılıyor.
      if (this.my_item_index_list.length === 0) {
        return false;
      }
      for (let i in this.my_item_index_list) {
        let one_back_index = '';
        if (team_member) {
          let arr = this.my_item_index_list[i].split(',');
          arr.splice(arr.length - 1, 1);
          one_back_index = arr.join(',');
        }
        if (this.selected_item_data.index.indexOf(this.my_item_index_list[i]) === 0 || (team_member && this.selected_item_data.index.indexOf(one_back_index) === 0)) {
          if (!can_use_me) {
            if (this.my_item_index_list[i] !== this.selected_item_data.index) {
              return true;
            }
          } else {
            return true;
          }
        }
      }
      return false;
    },
    f_taskStatusThis: function (status_list, task_data) {
      try {
        if (status_list.indexOf(task_data.data.general.status.val.value) !== -1) {
          return true;
        }
      } catch (err) {
        return false;
      }
    },
    f_calculateStatusStyle: function (status_val) {
      if (status_val === 'completed') {
        return 'background: #28a745; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; width: 100%; white-space: nowrap;';
      } else if (status_val === 'cancelled') {
        return 'background: #f7959e; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; width: 100%; white-space: nowrap;';
      } else if (status_val === 'assigned') {
        return 'background: #f3ff00; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; width: 100%; white-space: nowrap;';
      } else if (status_val === 'not_assigned') {
        return 'background: #a3afb6; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; width: 100%; white-space: nowrap;';
      } else if (status_val === 'recommended') {
        return 'background: #e08be7; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; width: 100%; white-space: nowrap;';
      } else if (status_val === 'refused') {
        return 'background: #c1a4fc; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; width: 100%; white-space: nowrap;';
      } else if (status_val === 'accepted') {
        return 'background: #01ffe4; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; width: 100%; white-space: nowrap;';
      } else {
        return 'background: orange; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; width: 100%; white-space: nowrap;';
      }
    },
    f_controlOnlyMyPermission: function (perm) {
      for (let i in this.my_item_index_list) {
        let itemData = this.f_getTreeLocation(this.my_item_index_list[i].split(','), 'item_data');
        if (itemData.perms && itemData.perms.indexOf(perm) !== -1) {
          return true
        }
      }
      return false;
    },
    f_deleteTaskFromAllUsers: function (list_data, task_key) {
      for (let i in list_data) {
        if (list_data[i].task_list && list_data[i].task_list.indexOf(task_key) !== -1) {
          list_data[i].task_list.splice(list_data[i].task_list.indexOf(task_key), 1);
        }
        if ('list' in list_data[i] && list_data[i].list.length > 0) {
          this.f_deleteTaskFromAllUsers(list_data[i].list, task_key);
        }
      }
    },
    f_giveTaskToSelectedLayer: function (type, task_ind) {
      let task_data = JSON.parse(JSON.stringify(this.d_taskList[task_ind]));
      let task_key = 'organization_' + task_data['organization_id'] + '_task_' + task_data['id'];
      if (!this.selected_item_data.task_list || (this.selected_item_data.task_list && this.selected_item_data.task_list.indexOf(task_key) === -1)) {
        if (type === 'transfer') {
          this.f_deleteTaskFromAllUsers(this.tree_list_data.list, task_key);
          this.$delete(this.d_taskList[task_ind], 'user_index_list');
        }
        if (!this.selected_item_data.task_list) {
          this.selected_item_data.task_list = [];
        }
        this.selected_item_data.task_list.push(task_key);
        if (!this.d_taskList[task_ind].user_index_list) {
          this.d_taskList[task_ind].user_index_list = {};
        }
        this.d_taskList[task_ind].user_index_list[this.selected_item_data.value] = {
          'index_list': [],
          'label': this.selected_item_data.label,
          'value': this.selected_item_data.value
        };
        if (this.d_taskList[task_ind].user_index_list[this.selected_item_data.value].index_list.indexOf(this.selected_item_data.index) === -1) {
          this.d_taskList[task_ind].user_index_list[this.selected_item_data.value].index_list.push(this.selected_item_data.index);
        }
        this.$forceUpdate();
        this.f_saveTaskStatus({ 'label': 'Atandı', 'value': 'assigned' }, task_ind, 'all_task_list');
        this.f_saveOrganization();
      } else {
        alert('Bu görev bu kullanıcıda zaten eklidir.');
      }
    },
    f_controlMyLayerPermission: function (perm, can_use_me = false) {
      // aktif kullanıcının my_item_index_listi oluşturulduğundan bu kullanıcının hem yetkisi hem de amir olup olmadığı kontrol ediliyor.
      // bir aktif kullanıcı başka bir kullanıcının üzerinde birden fazla lokalizasyonda olabilir ve bu lokalizasyonlardaki izinleri de farklı olabilir
      // dolayısıyla önce bu izinlerini perm_liste topluyoruz.
      if (this.my_item_index_list.length === 0) {
        return false;
      }
      let det = false;
      let perm_list = [];
      for (let i in this.my_item_index_list) {
        if (this.selected_item_data.index.indexOf(this.my_item_index_list[i]) === 0) {
          if (!can_use_me) {
            if (this.my_item_index_list[i] !== this.selected_item_data.index) {
              let itemData = this.f_getTreeLocation(this.my_item_index_list[i].split(','), 'item_data');
              if (itemData.perms && itemData.perms.length > 0) {
                for (let i in itemData.perms) {
                  if (perm_list.indexOf(itemData.perms[i]) === -1) {
                    perm_list.push(itemData.perms[i]);
                  }
                }
              }
            }
          } else {
            let itemData = this.f_getTreeLocation(this.my_item_index_list[i].split(','), 'item_data');
            if (itemData.perms && itemData.perms.length > 0) {
              for (let i in itemData.perms) {
                if (perm_list.indexOf(itemData.perms[i]) === -1) {
                  perm_list.push(itemData.perms[i]);
                }
              }
            }
          }
        }
      }
      if (perm_list.indexOf(perm) !== -1) {
        return true;
      }
      return false;
    },
    f_createIndexForAllItems: function (list_data, result) {
      for (let i in list_data) {
        if (parseInt(i) === 0) {
          result.item_index.push(i);
        } else {
          result.item_index[result.item_index.length - 1] = i;
        }
        list_data[i].index = result.item_index.join(',');
        if ('list' in list_data[i] && list_data[i].list.length > 0) {
          this.f_createIndexForAllItems(list_data[i].list, result);
          result.item_index.splice(result.item_index.length - 1, 1);
        }
      }
    },
    f_getUserIndexList: function (list_data, username, index_list) {
      for (let i in list_data) {
        if (list_data[i].isTitle === 'no') {
          if (!this.d_organizationHead) {
            this.d_organizationHead = list_data[i].index;
          }
          if (list_data[i].value === username) {
            index_list.push(list_data[i].index);
          }
          if (!this.d_allOrganizationUsers[list_data[i].value]) {
            this.d_allOrganizationUsers[list_data[i].value] = { 'pic': '' };
            let u_key = 'user_' + list_data[i].value + '_picture';
            if (this.d_allOrganizationUserPictureKeyList.indexOf(u_key) === -1) {
              this.d_allOrganizationUserPictureKeyList.push(u_key);
            }
          }
        }
        if ('list' in list_data[i] && list_data[i].list.length > 0) {
          this.f_getUserIndexList(list_data[i].list, username, index_list);
        }
      }
    },
    f_getTaskList: function (selected_layer = [], type = 'selected_item_task_list') {
      this.d_searchTaskListData.selected_layer = selected_layer;
      this.d_searchTaskListData.active_user_index_list = this.my_item_index_list;
      this.d_searchTaskListData.username = this.user.username;
      this.d_searchTaskListData.organization_id = this.organization_id;
      this.d_searchTaskListData.pagination = this.d_pagination;
      this.d_searchTaskListData.search_text = '';
      OrganizationService.get_task_list(this.d_searchTaskListData)
        .then(resp => {
          if (resp.data.status === 'success') {
            if (type === 'selected_item_task_list') {
              this.d_selectedItemTaskList = resp.data.result.list;
              this.d_selectedItemTaskListCount = resp.data.result.count;
            } else {
              this.d_taskList = resp.data.result.list;
              this.d_taskListCount = resp.data.result.count;
            }
          } else {
            alert('error ' + resp.data.message);
          }
        });
    },
    f_saveTaskStatus: function (selected_status, task_ind, type) {
      let task_data = {};
      this.selected_task_index = task_ind;
      this.selected_task_list_type = type;
      if (type === 'all_task_list') {
        task_data = JSON.parse(JSON.stringify(this.d_taskList[task_ind]));
      } else {
        task_data = JSON.parse(JSON.stringify(this.d_selectedItemTaskList[task_ind]));
      }
      task_data.data.general.status = { 'val': selected_status };
      if (!task_data.data.general.log) {
        task_data.data.general.log = { 'list': [] };
      }
      let x = {
        'log_user': {
          'val': {
            'label': this.user.first_name + ' ' + this.user.last_name,
            'value': this.user.username
          }
        },
        'which_user_log': {
          'val': {
            'label': this.selected_item_data.label,
            'value': this.selected_item_data.value
          }
        },
        'log_status': {
          'val': selected_status
        },
        'log_datetime': {
          'val': 'new'
        }
      };
      task_data.data.general.log.list.push(JSON.parse(JSON.stringify(x)));
      OrganizationService.save_task(task_data)
        .then(resp => {
          if (resp.data.status === 'success') {
            let task_key = 'organization_' + resp.data.result['organization_id'] + '_' + 'task_' + resp.data.result['id'];
            if (!this.selected_item_data.task_list) {
              this.selected_item_data.task_list = [];
            }
            if (['not_assigned', 'cancelled'].indexOf(selected_status.value) !== -1) {
              this.selected_item_data.task_list.splice(this.selected_item_data.task_list.indexOf(task_key), 1);
              this.d_selectedItemTaskList.splice(task_ind, 1);
            } else {
              if (this.selected_item_data.task_list.indexOf(task_key) === -1) {
                this.selected_item_data.task_list.push(task_key);
              }
              let det = false;
              for (let i in this.d_selectedItemTaskList) {
                if (this.d_selectedItemTaskList[i].id === resp.data.result.id) {
                  det = true;
                  this.d_selectedItemTaskList[i] = resp.data.result;
                  break;
                }
              }
              if (!det) {
                this.d_selectedItemTaskList.push(resp.data.result);
              }
            }
            this.$forceUpdate();
            this.show_wisdom_data_modal = false;
            this.f_saveOrganization();
          } else {
            alert('error ' + resp.data.message);
          }
        });
    },
    f_editTask: function (task_ind, type = 'edit_selected_item') {
      this.d_taskSaveMode = type;
      this.data_type = 'wdm17';
      if (type === 'edit_selected_item') {
        this.d_newTaskUserType = 'user';
        this.wisdom_data = JSON.parse(JSON.stringify(this.d_selectedItemTaskList[task_ind]));
      } else if (type === 'edit_all_task_list') {
        this.d_newTaskUserType = 'not_user';
        this.wisdom_data = JSON.parse(JSON.stringify(this.d_taskList[task_ind]));
      }
      for (let i in this.option_data[this.data_type].param_group.param_order) {
        if (!this.wisdom_data['data'][this.option_data[this.data_type].param_group.param_order[i]]) {
          this.wisdom_data['data'][this.option_data[this.data_type].param_group.param_order[i]] = {};
        }
      }
      this.show_wisdom_data_modal = true;
    },
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
    },
    f_saveTask: function () {
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name', 'delete']);
      let wdmr_name = return_data.name;
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Görev kayıt işlemi tamamlanıyor.' } });
      OrganizationService.save_task(this.wisdom_data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.show_wisdom_data_modal = false;
            if (this.d_newTaskUserType === 'user') {
              let task_key = 'organization_' + resp.data.result['organization_id'] + '_' + 'task_' + resp.data.result['id'];
              if (!this.selected_item_data.task_list) {
                this.selected_item_data.task_list = [];
              }
              if (this.selected_item_data.task_list.indexOf(task_key) === -1) {
                this.selected_item_data.task_list.push(task_key);
              }
            }
            this.f_saveOrganization();
            this.$forceUpdate();
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            alert('error ' + resp.data.message);
          }
        });
    },
    f_addNewTask: function (data_type, status, user_type, selected_index = '', mail = 'no') {
      this.d_taskSaveMode = 'new_task';
      this.d_newTaskUserType = user_type;
      this.data_type = data_type;
      let pre_data = {
        'id': 'new',
        'data': {},
        'type': data_type,
        'reference_wdm': {},
        'parent': data_type,
        'organization_id': this.tree_list_data.id,
        'selected_index': [selected_index], // ilerde çoklu katmana hızlı atama yapılabileceği için böyle yapıldı
        'mail': mail
      };
      pre_data['reference_wdm']['version'] = this.option_data[this.data_type]['version'];
      pre_data['reference_wdm']['owner_type'] = this.option_data[this.data_type]['owner_type'];
      pre_data['reference_wdm']['key'] = 'wdm_wisdom_' + this.data_type + '_v' + this.option_data[this.data_type].version;
      for (let i in this.option_data[this.data_type].param_group.param_order) {
        pre_data['data'][this.option_data[this.data_type].param_group.param_order[i]] = {};
      }
      let selected_status = '';
      for (let i in this.d_wdm17StateOptions) {
        if (this.d_wdm17StateOptions[i].value === status) {
          selected_status = JSON.parse(JSON.stringify(this.d_wdm17StateOptions[i]));
          pre_data.data.general.status = { 'val': JSON.parse(JSON.stringify(this.d_wdm17StateOptions[i])) };
          break;
        }
      }
      if (user_type === 'user') {
        if (!pre_data.data.general.log) {
          pre_data.data.general.log = { 'list': [] };
        }
        let x = {
          'log_user': {
            'val': {
              'label': this.user.first_name + ' ' + this.user.last_name,
              'value': this.user.username
            }
          },
          'which_user_log': {
            'val': {
              'label': this.selected_item_data.label,
              'value': this.selected_item_data.value
            }
          },
          'log_status': {
            'val': selected_status
          },
          'log_datetime': {
            'val': 'new'
          }
        };
        pre_data.data.general.log.list.push(JSON.parse(JSON.stringify(x)));
      }
      this.wisdom_data = pre_data;
      this.show_wisdom_data_modal = true;
    },
    f_saveOrganization: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Index bilgileri güncelleniyor' } });
      this.f_createIndexForAllItems(this.tree_list_data.list, { 'item_index': [] });
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Organizasyon kaydediliyor.' } });
      OrganizationService.save_organization(this.tree_list_data)
        .then(resp => {
          if (resp.data.status === 'success') {
            // let msg = 'Organizasyon kayıt işlemi başarıyla tamamlandı';
            // let modal_data = {
            //   'type': 'alert',
            //   'header_bg_variant': 'info',
            //   'header_text_variant': 'dark',
            //   'text': msg,
            //   'centered': true,
            //   'title': 'Organizasyon Kayıt İşlemi'
            // };
            // this.$store.commit('modal', modal_data);
            // this.$store.commit('modal_show', true);
            if (this.d_watchTaskList.get_task_list === 1) {
              this.d_watchTaskList.get_task_list = 0;
            } else {
              this.d_watchTaskList.get_task_list = 1;
            }
            this.f_getTaskListWithWatch();
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            alert('error ', resp.data.message);
          }
        });
    },
    f_formatValueRegex (value, event) {
      return WisdomRegex.regex1(value).value;
    },
    f_checkValueRegex (value) {
      return WisdomRegex.regex1(value).status;
    },
    GetClinicalBranchesOfHospital: function () {
      if (this.location && this.location === 'hospital') {
        let hospital_group = this.hospital_data ? this.hospital_data.hospital_group : '';
        let hospital_code = this.hospital_data ? this.hospital_data.hospital_id : '';
        let query = 'HospitalGroup=' + hospital_group + '&' + 'hospitalCode=' + hospital_code;
        WebService.get_clinical_branches_of_hospital(query)
          .then(resp => {
            this.HospitalClinicalBranchResult = resp.data;
          });
      }
    },
    f_showSelectedItemsParents: function () {
      if (this.show_selected_item_parents) {
        let result = { 'item_index': [], 'parentList': [] };
        this.f_showSelectedItemsParentsList(this.tree_list_data['list'], result);
        this.d_parentList = result.parentList;
      }
    },
    f_showSelectedItemsParentsList: function (data_list, result) {
      for (let i in data_list) {
        if (parseInt(i) === 0) {
          result.item_index.push(i);
        } else {
          result.item_index[result.item_index.length - 1] = i;
        }
        if (data_list[i].isTitle === 'no' && this.f_controlThisItemIsInSelectedItems(data_list[i].value)) {
          result.parentList.push(result.item_index.join(','));
        } else if (data_list[i].list && data_list[i].list.length > 0) {
          let x = { 'y': false };
          this.f_lookForInAllItems(data_list[i].list, x);
          if (x['y']) {
            result.parentList.push(result.item_index.join(','));
          }
          this.f_showSelectedItemsParentsList(data_list[i].list, result);
          result.item_index.splice(result.item_index.length - 1, 1);
        }
      }
    },
    f_lookForInAllItems: function (data_list, x) {
      if (!x['y']) {
        for (let i in data_list) {
          if (data_list[i].isTitle === 'no' && this.f_controlThisItemIsInSelectedItems(data_list[i].value)) {
            x['y'] = true;
          } else {
            if (data_list[i].list && data_list[i].list.length > 0 && !x['y']) {
              this.f_lookForInAllItems(data_list[i].list, x);
            }
          }
        }
      }
    },
    f_controlThisItemIsInSelectedItems: function (item_value) {
      let det = false;
      for (let i in this.eligible_items_list) {
        if (this.eligible_items_list[i].value === item_value) {
          det = true;
          break;
        }
      }
      if (det) {
        return true;
      } else {
        return false;
      }
    },
    f_selectSelectedItemsDataList: function () {
      let item_list = JSON.parse(JSON.stringify(this.selected_items_data_list));
      if (item_list.length > 0) {
        let new_selected_items = [];
        for (let i in item_list) {
          // console.log('DATATYPE: ', item_list[i].label);
          let result = { 'item_index': [], 'new_selected_items': [] };
          this.f_getItemIndexLocation(this.tree_list_data['list'], item_list[i], false, result);
          if (result.new_selected_items.length > 0) {
            new_selected_items = new_selected_items.concat(result.new_selected_items);
            // console.log('new_selected_items', new_selected_items);
          }
        }
        this.selected_items = new_selected_items;
      }
    },
    f_getItemIndexLocation: function (data_list, itemData, isTitle, result) {
      for (let i in data_list) {
        if (parseInt(i) === 0) {
          result.item_index.push(i);
        } else {
          result.item_index[result.item_index.length - 1] = i;
        }
        if (isTitle && data_list[i].value === itemData.value) {
          result.new_selected_items.push(result.item_index.join(','));
          // data_list[i] = JSON.parse(JSON.stringify(itemData));
        } else if (!isTitle && data_list[i].isTitle === 'no' && data_list[i].value === itemData.value) {
          result.new_selected_items.push(result.item_index.join(','));
          // data_list[i] = JSON.parse(JSON.stringify(itemData));
        }
        if (data_list[i].list && data_list[i].list.length > 0) {
          this.f_getItemIndexLocation(data_list[i].list, itemData, isTitle, result);
          result.item_index.splice(result.item_index.length - 1, 1);
        }
      }
    },
    controlEmptyValueOrLabel: function (type, listData, det) {
      // sonsuz döngü ile çalışıyor
      for (let i in listData) {
        if (!det.x) {
          if (listData[i][type] === '') {
            det.x = true;
          }
          if (listData[i].list && listData[i].list.length > 0) {
            this.controlEmptyValueOrLabel(type, listData[i].list, det);
          }
        }
      }
    },
    f_saveTreeListData: function () {
      let warning_msg = '';
      let empty_value_det = { 'x': false };
      let empty_label_det = { 'x': false };
      this.controlEmptyValueOrLabel('value', this.tree_list_data.list, empty_value_det);
      this.controlEmptyValueOrLabel('label', this.tree_list_data.list, empty_label_det);

      if (this.d_regexAlert) {
        warning_msg += "En az bir adet Değer girişinizde hata bulunmaktadır\n";
      }
      if (this.value_alert) {
        warning_msg += "En az bir adet Değer girişinizde tekrar bulunmaktadır\n";
      }
      if (this.label_alert) {
        warning_msg += "En az bir adet Başlık girişinizde tekrar bulunmaktadır\n";
      }
      if (empty_value_det.x) {
        warning_msg += "En az bir adet Değer boş olarak bulunmaktadır\n";
      }
      if (empty_label_det.x) {
        warning_msg += "En az bir adet Başlık boş olarak bulunmaktadır\n";
      }

      if (warning_msg.length > 0) {
        warning_msg += "Lütfen belirtilen hataları düzeltiniz";
        let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': warning_msg, 'centered': true, 'title': 'WTL Kayıt İşlemi Hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      } else {
        this.show_tree_data_settings = false;
        let hospital_group = this.hospital_data ? this.hospital_data.hospital_group : '';
        let hospital_code = this.hospital_data ? this.hospital_data.hospital_id : '';
        let location = this.location ? this.location : 'wisdom';
        TreeListService.saveTreeList(this.tree_list_type, location, this.user.username, hospital_group, hospital_code, this.tree_list_data)
          .then(resp => {
            // console.log(resp.data);
            if (resp.data.status === 'success') {
              this.tree_list_data = resp.data.result;
              this.tree_list_data_bck_data = JSON.parse(JSON.stringify(this.tree_list_data));
              let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': 'kayıt tamamlandı', 'centered': true, 'title': 'WTL Kayıt İşlemi Hakkında' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            } else {
              let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': resp.data.message, 'centered': true, 'title': 'WTL Kayıt İşlemi Tamamlanamadı' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
      }
    },
    fResetTreeListData: function () {
      this.tree_list_data = JSON.parse(JSON.stringify(this.tree_list_data_bck_data));
    },
    f_followUpLocation: function (item) {
      if (this.tree_list_type === 'module_and_model') {
        if (!this.follow_up_location) {
          return true;
        } else {
          if (item.follow_up_location && item.follow_up_location.indexOf(this.follow_up_location) !== -1) {
            return true;
          }
        }
        return false;
      } else {
        return true;
      }
    },
    fSearchItem: function (item_index_list) {
      if (!this.search_text) {
        if (this.show_selected_item_parents) {
          if (this.d_parentList.indexOf(item_index_list.join()) !== -1) {
            return true;
          }
        } else {
          return true;
        }
      } else {
        if (this.searchIndexList.length > 0) {
          for (let i in this.searchIndexList) {
            let x = true;
            for (let k in item_index_list) {
              if (parseInt(this.searchIndexList[i][k]) !== item_index_list[k]) {
                x = false;
                break;
              }
            }
            if (x) {
              if (this.show_selected_item_parents) {
                if (this.d_parentList.indexOf(item_index_list.join()) !== -1) {
                  return true;
                }
              } else {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    fSelectThisItem (item_index_list) {
      // mevcut index seçimini silip yeni indexi seçililere ekliyoruz.
      this.selected_items.splice(0, item_index_list.length);
      this.selected_items.push(item_index_list.join());
    },
    f_getTreeLocation: function (item_index_list, type) {
      let listLoc = this.tree_list_data.list;
      for (let i in item_index_list) {
        if (parseInt(i) !== item_index_list.length - 1) {
          listLoc = listLoc[item_index_list[i]].list;
          // console.log('listLoc: ', listLoc);
        } else {
          if (type === 'item_data') {
            return listLoc[item_index_list[i]];
          } else if (type === 'list') {
            // console.log('listLoc:', listLoc);
            return listLoc;
          }
        }
      }
      if (type === 'list') {
        return listLoc;
      } else if (type === 'item_data') {
        return this.tree_list_data;
      }
    },
    f_addNewItem: function (item_index_list) {
      let perm = true;
      if (this.tree_list_type === 'organization') {
        if (this.my_item_index_list.length === 0) {
          alert('Bu organizasyonda kayıtlı gözükmüyorsunuz');
          return;
        }
        let det = false;
        for (let i in this.my_item_index_list) {
          let itemData = this.f_getTreeLocation(this.my_item_index_list[i].split(','), 'item_data');
          // aktif kullanıcı ekleme planlanan alanın amiri mi ona bakıyoruz. sıfırıncı indexten başlıyorsa amiridir.
          // amiri olduğu itemın datasındaki izin yetkisini kontrol ediyoruz. Çünkü başka bir katmanda ilgili izni olmayabilir.
          if (item_index_list.join(',').indexOf(this.my_item_index_list[i]) === 0 && itemData.perms && itemData.perms.indexOf('can_manage_sub_layer') !== -1) {
            det = true;
            break;
          }
        }
        if (!det) {
          perm = false;
          alert('Bu katmanı yönetme yetkiniz yok.');
        }
      }
      if (perm) {
        let itemData = this.f_getTreeLocation(item_index_list, 'item_data');
        // console.log('item_index_list: ', item_index_list);
        itemData.list.push({ 'label': 'Yeni', 'value': '', 'isTitle': 'no', 'list': [], 'index': '' });
        item_index_list.push(itemData.list.length - 1);
        this.f_createIndexForAllItems(this.tree_list_data.list, { 'item_index': [] });
        this.fSelectThisItem(item_index_list);
      }
    },
    fCalculateAddIconSpanList: function (tree_deep_count) {
      let html_str = '';
      for (let i = 0; i < tree_deep_count; i++) {
        if (i === tree_deep_count - 1) {
          html_str += '<span style="padding-left: 20px; border-left: dotted 1px #20a8d8; border-bottom: dotted 1px #20a8d8;"></span>';
        } else {
          html_str += '<span style="padding-left: 20px; border-left: dotted 1px #20a8d8;"></span>';
        }
      }
      return html_str;
    },
    CalculateTreeStyle: function () {
      if (this.max_height) {
        return 'height: ' + this.max_height + 'px; overflow-y: auto; overflow-x: auto; margin-left: 10px;';
      } else {
        return 'height: ' + (window.innerHeight - 85 - 62 - 27).toString() + 'px; overflow-y: auto; overflow-x: auto; margin-left: 10px;';
      }
    },
    calcMaxHeight: function () {
      return 'height: ' + (window.innerHeight - 85).toString() + 'px';
    },
    SelectGroup (level, item_index1, item_index2 = '', item_index3 = '') {
      // console.log('xxxxxxxxxx', level, item_index1, item_index2, item_index3);
      this.selected_group_data = { 'value': '', 'label': '' };
      this.selected_level = level;
      this.selected_index1 = item_index1;
      this.selected_index2 = item_index2;
      this.selected_index3 = item_index3;
      if (level === 1) {
        this.selected_group_data = this.tree_list_data.list[item_index1];
      } else if (level === 2) {
        this.selected_group_data = this.tree_list_data.list[item_index1].list[item_index2];
      } else if (level === 3) {
        this.selected_group_data = this.tree_list_data.list[item_index1].list[item_index2].list[item_index3];
      }
      // console.log(this.selected_group_data);
    },
    add_new_group_category: function (level, item_index1 = '', item_index2 = '') {
      this.selected_index1 = '';
      this.selected_index2 = '';
      this.selected_index3 = '';
      this.selected_level = level;
      if (level === 1) {
        if (!this.tree_list_data.list) {
          this.tree_list_data.list = [];
        }
        this.tree_list_data.list.push({ 'label': 'Yeni', 'list': [] })
        this.selected_index1 = this.tree_list_data.list.length - 1;
      } else if (level === 2) {
        if (!this.tree_list_data.list[item_index1].list) {
          this.tree_list_data.list[item_index1].list = [];
        }
        this.tree_list_data.list[item_index1].list.push({ 'label': 'Yeni', 'list': [] })
        this.selected_index1 = item_index1;
        this.selected_index2 = this.tree_list_data.list[item_index1].list.length - 1;
      } else if (level === 3) {
        if (!this.tree_list_data.list[item_index1].list[item_index2].list) {
          this.tree_list_data.list[item_index1].list[item_index2].list = [];
        }
        this.tree_list_data.list[item_index1].list[item_index2].list.push({ 'label': 'Yeni' })
        this.selected_index1 = item_index1;
        this.selected_index2 = item_index2;
        this.selected_index3 = this.tree_list_data.list[item_index1].list[item_index2].list.length - 1;
      }
      this.selected_group_data = { 'label': 'Yeni', 'list': [] };
      this.$forceUpdate();
      this.SelectGroup(level, this.selected_index1, this.selected_index2, this.selected_index3);
    },
    controlVariableIsContaining: function (type, listData, val, det_val) {
      // sonsuz döngü ile çalışıyor
      for (let i in listData) {
        if (listData[i][type] === val) {
          // console.log('val:', val);
          det_val.count += 1;
        }
        if (listData[i].list && listData[i].list.length > 0) {
          this.controlVariableIsContaining(type, listData[i].list, val, det_val);
        }
      }
    },
    controlRegexReturning: function (listData, regex_det) {
      // sonsuz döngü ile çalışıyor
      for (let i in listData) {
        if (this.f_checkValueRegex(listData[i]['value']) === false) {
          regex_det.x = true;
        }
        if (listData[i].list && listData[i].list.length > 0) {
          this.controlRegexReturning(listData[i].list, regex_det);
        }
      }
    },
    fSearchTreeListItem: function (list) {
      let lastIndex = '';
      let tmpLastSearchIndex = this.lastSearchIndex;
      for (let i in list) {
        if (this.lastSearchIndex) {
          lastIndex = this.lastSearchIndex + ',' + i;
        } else {
          lastIndex = i;
        }
        if (list[i].label.toLocaleLowerCase('tr').indexOf(this.search_text.toLocaleLowerCase('tr')) !== -1) {
          this.searchIndexList.push(lastIndex.split(','));
        }
        if (list[i].list && list[i].list.length > 0) {
          this.lastSearchIndex = lastIndex;
          this.fSearchTreeListItem(list[i].list);
          this.lastSearchIndex = tmpLastSearchIndex;
        }
      }
    },
    f_getTaskListWithWatch: function () {
      if (this.d_watchTaskList.get_task_list === 1) {
        this.d_watchTaskList.get_task_list = 0;
      } else {
        this.d_watchTaskList.get_task_list = 1;
      }
      // if (this.d_selectedOrganizationTab === 'user_task_list' && this.selected_item_data && this.selected_item_data.index && this.selected_item_data.isTitle && this.selected_item_data.isTitle === 'no' && this.selected_item_data.user) {
      //   // wca_start
      //   // this.selected_item_data.index => '0,0,4'
      //   // wca_end
      //   this.f_getTaskList([this.selected_item_data.index], 'selected_item_task_list');
      // } else if (this.d_selectedOrganizationTab === 'all_task_list') {
      //   if (this.d_searchTaskListData.user_type === 'selected_and_sub_layers') {
      //     let layer_index_list = [];
      //     layer_index_list.push(this.selected_item_data.index);
      //     this.f_getIndexListOfSubLayers(this.selected_item_data.list, layer_index_list);
      //     this.f_getTaskList(layer_index_list, 'all_task_list');
      //   } else {
      //     this.f_getTaskList([], 'all_task_list');
      //   }
      // }
    },
    f_getIndexListOfSubLayers: function (list_data, layer_index_list) {
      for (let i in list_data) {
        layer_index_list.push(list_data[i].index);
        if (list_data[i].list && list_data[i].list.length > 0) {
          this.f_getIndexListOfSubLayers(list_data[i].list, layer_index_list);
        }
      }
    },
    f_getNotAssignedBrandCount: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Henüz atanmamış ürün sayısı hesaplanıyor. Lütfen bekleyiniz.' } });
      OrganizationService.get_not_assigned_brand_count(this.tree_list_data.id)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.not_assigned_brand_count = resp.data.result.not_assigned_count;
          } else {
            alert('Atanmamış ürün sayısı hatası ' + resp.data.message);
          }
        });
    },
    f_changeHospitalGroup: function () {
      for (let i in this.d_hospitalGroupList) {
        if (this.d_hospitalGroupList[i].value === this.d_hospitalGroup) {
          this.d_hospitalList = JSON.parse(JSON.stringify(this.d_hospitalGroupList[i].hospital_list));
          this.$forceUpdate();
          break;
        }
      }
    },
    f_getHospitalGroup: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hastane listesi yükleniyor' } });
      HospitalService.get_all_hospitals_list()
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_hospitalGroupList = JSON.parse(JSON.stringify(resp.data.result));
            this.f_changeHospitalGroup();
          }
        });
    },
    ClickEnter (event) {
      if (event.which === 13) {
        this.f_searchPatient();
      }
    },
    f_searchPatient: function () {
      if (this.d_searchText && this.d_searchText !== '') {
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hasta listesi getiriliyor.' } });
        PatientService.search_from_patientlist({ 'search': this.d_searchText, 'document_key': 'hospital_patientlist_' + this.d_hospitalGroup + "_" + this.d_hospitalId, 'document_bucket': 'hospital' })
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            if (resp.data.status === 'success') {
              this.d_projectPatientList.list = resp.data.result;
            }
            if (this.d_searchText === '%') {
              this.d_searchText = '';
            }
            this.$forceUpdate();
          });
      } else {
        alert('Lutfen arama kriteri giriniz.');
      }
    }
  },
  watch: {
    'd_hospitalId': function () {
      this.d_projectPatientList = {};
      this.d_searchText = '';
    },
    'd_hospitalGroup': function () {
      this.d_projectPatientList = {};
      this.d_searchText = '';
    },
    'd_selectedPatientDataProject': function () {
      this.d_projectPatientList = {};
      this.d_searchText = '';
    },
    'd_patientListType': function () {
      if (this.d_patientListType === 'hospital') {
        this.f_getHospitalGroup();
      }
      this.d_projectPatientList = {};
      this.d_searchText = '';
    },
    'd_watchTaskList.save_organization': function () {
      this.f_saveOrganization();
    },
    'd_selectedOrganizationTab': function () {
      if (this.d_canGetTaskListWithWatch) {
        this.d_pagination.start = 0;
        this.d_pagination.end = 50;
        this.f_getTaskListWithWatch();
        if (this.d_selectedOrganizationTab === 'drug_medical_equipment') {
          this.f_getNotAssignedBrandCount();
        } else if (this.d_selectedOrganizationTab === 'patient_data_project') {
          this.f_getPatientDataProjectList();
        }
      }
    },
    'd_searchTaskListData.priority': function () {
      if (this.d_canGetTaskListWithWatch) {
        this.f_getTaskListWithWatch();
      }
    },
    'd_searchTaskListData.state_list': function () {
      if (this.d_canGetTaskListWithWatch) {
        this.f_getTaskListWithWatch();
      }
    },
    'd_searchTaskListData.task_type_list': function () {
      if (this.d_canGetTaskListWithWatch) {
        this.f_getTaskListWithWatch();
      }
    },
    'd_searchTaskListData.user_type': function () {
      if (this.d_canGetTaskListWithWatch) {
        this.f_getTaskListWithWatch();
      }
    },
    'd_pagination.current': function () {
      this.d_pagination.start = (this.d_pagination.current - 1) * this.d_pagination.perpage;
      this.d_pagination.end = (this.d_pagination.perpage * this.d_pagination.current) - 1;
      if (this.d_canGetTaskListWithWatch) {
        this.f_getTaskListWithWatch();
      }
    },
    'search_text': function () {
      if (this.search_text) {
        this.searchIndexList = [];
        this.fSearchTreeListItem(this.tree_list_data.list);
      } else {
        this.searchIndexList = [];
      }
    },
    'change.x': function () {
      // console.log('change.x:', this.change.x);
      this.$forceUpdate();
    },
    'selected_items': function () {
      // console.log('selected_items', this.selected_items);
      if (this.d_watchTaskList.get_task_list === 1) {
        this.d_watchTaskList.get_task_list = 0;
      } else {
        this.d_watchTaskList.get_task_list = 1;
      }

      if (this.selected_items_data_list.length > 0) {
        this.selected_items_data_list.splice(0, this.selected_items_data_list.length);
      }
      for (let i in this.selected_items) {
        let item_data = JSON.parse(JSON.stringify(this.f_getTreeLocation(this.selected_items[i].split(','), 'item_data')));
        this.$delete(item_data, 'list');
        this.selected_items_data_list.push(item_data);
      }
      if (this.mode === 'edit' && this.selected_items.length > 0) {
        let item_index_list = this.selected_items[0].split(',');
        // console.log('item_index_list', item_index_list);
        this.selected_item_data = this.f_getTreeLocation(item_index_list, 'item_data');
        if (!this.selected_item_data.perms) {
          this.selected_item_data.perms = [];
        }
      }
      // organization task control
      this.d_selectedItemTaskList = [];

      // if (this.tree_list_type === 'organization' && !this.selected_item_data.perms) {
      //   this.selected_item_data.perms = [];
      // }
      // if (this.tree_list_type === 'organization') {
      //   if (['user_details', 'user_task_list'].indexOf(this.d_selectedOrganizationTab) === -1) {
      //     this.d_selectedOrganizationTab = 'user_details';
      //   }
      // }
      // if (this.tree_list_type === 'organization' && this.selected_item_data.task_list && this.selected_item_data.task_list.length > 0) {
      //   this.f_getTaskList([this.selected_item_data.index], 'selected_item_task_list');

      //   GlobalService.get_multiple_cb_documents({ 'key_list': this.selected_item_data.task_list, 'bucket': 'default' })
      //     .then(resp => {
      //       if (resp.data.status === 'success') {
      //         for (let i in resp.data.result) {
      //           this.d_selectedItemTaskList.push(resp.data.result[i]);
      //         }
      //       } else {
      //         console.log('error:  ' + resp.data.message);
      //       }
      //     });

      // }
      if (this.follow_up_location) {
        if (this.tree_list_type === 'module_and_model') {
          let tmp_list = [];
          let wdm_list = [];
          for (let i in this.selected_items_data_list) {
            // birden fazla modül içerisinde aynı veri modeli olabilir. Gereksiz olarak push etmeyi engelliyoruz.
            if (tmp_list.indexOf(this.selected_items_data_list[i].value) === -1) {
              tmp_list.push(this.selected_items_data_list[i].value);
              let wdm = {
                'label': this.selected_items_data_list[i].label,
                'value': this.selected_items_data_list[i].value,
                'record_count': this.selected_items_data_list[i].record_count,
                'department_filter_mode': this.selected_items_data_list[i].department_filter_mode,
                'send_to_hims_protocol': this.selected_items_data_list[i].send_to_hims_protocol,
                'color': this.selected_items_data_list[i].color ? this.selected_items_data_list[i].color : ''
              };
              wdm_list.push(wdm);
            }
          }
          this.$store.commit('wdm', { 'wdm_list': wdm_list, 'ful': this.follow_up_location });
        } else if (this.tree_list_type === 'department') {
          let tmp_list = [];
          let department_list = [];
          for (let i in this.selected_items_data_list) {
            // birden fazla modül içerisinde aynı veri modeli olabilir. Gereksiz olarak push etmeyi engelliyoruz.
            if (tmp_list.indexOf(this.selected_items_data_list[i].value) === -1) {
              tmp_list.push(this.selected_items_data_list[i].value);
              department_list.push({ 'label': this.selected_items_data_list[i].label, 'value': this.selected_items_data_list[i].value });
            }
          }
          this.$store.commit('department', { 'department_list': department_list, 'ful': this.follow_up_location });
        }
      }
    },
    'selected_item_data.user': function () {
      if (['organization'].indexOf(this.tree_list_type) !== -1) {
        if (this.selected_item_data.user && this.selected_item_data.isTitle === 'no') {
          this.selected_item_data.label = this.selected_item_data.user.label;
          this.selected_item_data.value = this.selected_item_data.user.value;
          this.$forceUpdate();
        }
      }
    },
    'selected_item_data.isTitle': function () {
      if (['organization'].indexOf(this.tree_list_type) !== -1) {
        if (this.selected_item_data.isTitle === 'yes') {
          delete this.selected_item_data.user;
          this.selected_item_data.value = this.selected_items[0];
        }
        this.$forceUpdate();
      }
    },
    'selected_item_data.value': function () {
      if (['organization', 'task_tree_list'].indexOf(this.tree_list_type) === -1) {
        if (this.selected_item_data && this.selected_item_data.value && this.is_group_value_cannot_be_same) {
          this.value_alert = false;
          let det_val = { 'count': 0 };
          let regex_det = { 'x': false };
          this.controlVariableIsContaining('value', this.tree_list_data.list, this.selected_item_data.value, det_val);
          this.controlRegexReturning(this.tree_list_data.list, regex_det);
          if (regex_det.x === true) {
            this.d_regexAlert = true;
          } else {
            this.d_regexAlert = false;
          }
          if (det_val.count > 1) {
            this.value_alert = true;
            // yazılan değerin son değerini siliyor
            // this.selected_group_data.value = this.selected_group_data.value.substring(0, this.selected_group_data.value.length - 1);
          }
        } else {
          this.value_alert = false;
        }
      }
      if (['task_tree_list'].indexOf(this.tree_list_type) !== -1) {
        if (this.selected_item_data && this.selected_item_data.value && this.selected_item_data.value !== '') {
          this.d_taskDetail = {};
          this.d_showTaskDetail = false;
          GlobalService.get_one_cb_document({ 'key': 'organization_' + this.organization_id + '_' + this.selected_item_data.value, 'bucket': 'default' })
            .then(resp => {
              if (resp.data.status === 'success') {
                this.d_taskDetail = resp.data.result;
                this.d_showTaskDetail = true;
              } else {
                alert('Task döküman hatası: ' + resp.data.message);
              }
            });
        }
      }
    },
    'selected_item_data.label': function () {
      if (this.selected_item_data && this.selected_item_data.label && this.is_group_label_cannot_be_same) {
        this.label_alert = false;
        let det_val = { 'count': 0 };
        this.controlVariableIsContaining('label', this.tree_list_data.list, this.selected_item_data.label, det_val);
        if (det_val.count > 1) {
          this.label_alert = true;
          // yazılan değerin son değerini siliyor
          // this.selected_group_data.value = this.selected_group_data.value.substring(0, this.selected_group_data.value.length - 1);
        }
      } else {
        this.label_alert = false;
      }
    }
  }
}

</script>

<style type="text/css">
.tree-level-1-param {
  padding-left: 20px;
  margin-left: 20px;
  border-left: dotted 1px green;
  border-bottom: dotted 1px green;
}

.tree-level-1-param-left {
  padding-left: 20px;
  margin-left: 20px;
  border-left: dotted 1px green;
}

.tree-level-2-param {
  padding-left: 20px;
  /* margin-left: 20px; */
  border-left: dotted 1px green;
  border-bottom: dotted 1px green;
}

.tree-level-3-param-first {
  padding-left: 20px;
  /* margin-left: 40px; */
  border-left: dotted 1px green;
}

.tree-level-3-param {
  padding-left: 20px;
  /* margin-left: 20px; */
  border-left: dotted 1px green;
  border-bottom: dotted 1px green;
}

.btn-sm {
  padding: 0px 3px 0px 3px;
}

</style>

