<!-- !!!!!!!!!!!!! RİGHT BETWEEN DİV TAGS TO TEST YOUR RENDERED TEMPLATE THAT YOU LOGGED TO CONSOLE. -->
<!-- 
<template>
  <div></div>
</template>
 -->

<script>
import Vue from 'vue';
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';
import OptionService from '@/services/option';
import {
  default as TreeListItem
} from '@/components/widgets/TreeListItem';

var templateRenderFns = [];
export default {
  name: 'TreeLayer',
  render (h) {
    return h('div', [
      (this.template ? this.template() : '')
    ]);
  },
  staticRenderFns: templateRenderFns,
  components: {
    TreeListItem
  },
  props: {
    tree_list_type: {
      type: String,
      required: true
    },
    tree_list_data: {
      type: Object,
      required: true,
      default: {}
    },
    change: {
      type: Object,
      required: true
    },
    selected_item_data: {
      type: Object,
      required: false
    },
    selected_items: {
      type: Array,
      required: true
    },
    my_item_index_list: {
      type: Array,
      required: false
    },
    move_item: {
      type: Array,
      required: true
    },
    select_type: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
    pdt: {
      type: Object,
      required: false
    },
    edit_user: {
      type: String,
      required: false,
      default: ''
    },
    follow_up_location: {
      type: String,
      required: false,
      default: ''
    },
    d_parentList: {
      type: Array,
      required: true
    },
    searchIndexList: {
      type: Array,
      required: true
    },
    search_text: {
      type: String,
      required: false
    },
    show_selected_item_parents: {
      type: Boolean,
      required: false,
      default: false
    },
    item_adding_mode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: mapGetters({
    lang: 'lang',
    help: 'help',
    patient_tmp: "patient_selected"
  }),
  mounted () {},
  created: function () {
    this.render_template();
  },
  data () {
    return {
      template: null,
      d_levelCount: 20 // this is defined as default value, to be in safe.
    };
  },
  methods: {
    render_template: function () {
      this.d_level = 0; // When we call this function again, this.d_level must be 0.
      let result = { 'item_index': [], 'max': 0 };
      this.f_calculateMaximumDeepLevelCount(this.tree_list_data.list, result);
      this.d_levelCount = result.max;
      // console.log('this.d_levelCount: ', this.d_levelCount);
      let html = this.build_form();
      // console.log(html);
      let compiled_template = Vue.compile(html);
      this.template = compiled_template.render;
      templateRenderFns.length = 0;
      for (var i in compiled_template.staticRenderFns) {
        templateRenderFns.push(compiled_template.staticRenderFns[i]);
      }
    },
    build_form: function () {
      let html = '';
      html = this.f_createTree();
      html += '<b-row v-if="item_adding_mode">';
      html += '<b-col cols="12">';
      html += '<span v-html="fCalculateAddIconSpanList(0)"></span>';
      html += '<a @click="f_addNewItem([])"><i class="fa fa-plus" style="color: green; cursor: pointer;"></i></a>';
      html += '</b-col>';
      html += '</b-row>';
      return '<div>' + html + '</div>';
    },
    f_createTree: function () {
      let html = '';
      this.d_level += 1;
      html += '<template v-for="(item' + this.d_level.toString() + ', item_index' + this.d_level.toString() + ') in ' + this.f_treeListData() + '" v-if="fSearchItem([' + this.f_itemIndexList() + ']) && f_followUpLocation(item' + this.d_level.toString() + ')">';
      html += '<tree-list-item :tree_list_data="tree_list_data" :list="' + this.f_treeListData() + '" :item="item' + this.d_level.toString() + '" :item_index="[' + this.f_itemIndexList() + ']" :selected_items="selected_items" :my_item_index_list="my_item_index_list" :select_type="select_type" :mode="mode" :selected_item_data="selected_item_data" :change="change" :move_item="move_item" :pdt="pdt" :edit_user="edit_user" :tree_list_type="tree_list_type" :follow_up_location="follow_up_location"></tree-list-item>';
      if (this.d_level <= this.d_levelCount) {
        html += this.f_createTree();
        this.d_level -= 1;
      }
      html += '<b-row v-if="item_adding_mode">';
      html += '<b-col cols="12">';
      html += '<span v-html="fCalculateAddIconSpanList(' + this.d_level.toString() + ')"></span>';
      html += '<a @click="f_addNewItem([' + this.f_itemIndexList() + '])"><i class="fa fa-plus" style="color: green; cursor: pointer;"></i></a>';
      html += '</b-col>';
      html += '</b-row>';
      html += '</template>';
      return html;
    },
    f_calculateMaximumDeepLevelCount: function (list_data, result) {
      for (let i in list_data) {
        if (parseInt(i) === 0) {
          result.item_index.push(i);
        } else {
          result.item_index[result.item_index.length - 1] = i;
        }
        // console.log(result.item_index);
        if (result.item_index.length > result.max) {
          result.max = result.item_index.length;
        }
        list_data[i].index = result.item_index.join(',');
        if ('list' in list_data[i] && list_data[i].list.length > 0) {
          this.f_calculateMaximumDeepLevelCount(list_data[i].list, result);
          result.item_index.splice(result.item_index.length - 1, 1);
        }
      }
    },
    f_itemIndexList: function () {
      let str = '';
      for (let i = 1; i <= this.d_level; i++) {
        str += 'item_index' + i.toString();
        if (i !== this.d_level) {
          str += ', ';
        }
      }
      return str;
    },
    f_treeListData: function () {
      if (this.d_level === 1) {
        return 'tree_list_data.list';
      } else {
        return 'item' + (this.d_level - 1).toString() + '.list';
      }
    },
    f_followUpLocation: function (item) {
      if (this.tree_list_type === 'module_and_model') {
        if (!this.follow_up_location) {
          return true;
        } else {
          if (item.follow_up_location && item.follow_up_location.indexOf(this.follow_up_location) !== -1) {
            return true;
          }
        }
        return false;
      } else {
        return true;
      }
    },
    fSearchItem: function (item_index_list) {
      if (!this.search_text) {
        if (this.show_selected_item_parents) {
          if (this.d_parentList.indexOf(item_index_list.join()) !== -1) {
            return true;
          }
        } else {
          return true;
        }
      } else {
        if (this.searchIndexList.length > 0) {
          for (let i in this.searchIndexList) {
            let x = true;
            for (let k in item_index_list) {
              if (parseInt(this.searchIndexList[i][k]) !== item_index_list[k]) {
                x = false;
                break;
              }
            }
            if (x) {
              if (this.show_selected_item_parents) {
                if (this.d_parentList.indexOf(item_index_list.join()) !== -1) {
                  return true;
                }
              } else {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    f_addNewItem: function (item_index_list) {
      let perm = true;
      if (this.tree_list_type === 'organization') {
        if (this.my_item_index_list.length === 0) {
          alert('Bu organizasyonda kayıtlı gözükmüyorsunuz');
          return;
        }
        let det = false;
        for (let i in this.my_item_index_list) {
          let itemData = this.f_getTreeLocation(this.my_item_index_list[i].split(','), 'item_data');
          // aktif kullanıcı ekleme planlanan alanın amiri mi ona bakıyoruz. sıfırıncı indexten başlıyorsa amiridir.
          // amiri olduğu itemın datasındaki izin yetkisini kontrol ediyoruz. Çünkü başka bir katmanda ilgili izni olmayabilir.
          if (item_index_list.join(',').indexOf(this.my_item_index_list[i]) === 0 && itemData.perms && itemData.perms.indexOf('can_manage_sub_layer') !== -1) {
            det = true;
            break;
          }
        }
        if (!det) {
          perm = false;
          alert('Bu katmanı yönetme yetkiniz yok.');
        }
      }
      if (perm) {
        // console.log(item_index_list);
        let itemData = this.f_getTreeLocation(item_index_list, 'item_data');
        // console.log(itemData);
        // console.log('item_index_list: ', item_index_list);
        if (!itemData.list) {
          itemData.list = [];
        }
        itemData.list.push({ 'label': 'Yeni', 'value': '', 'isTitle': 'no', 'list': [], 'index': '' });
        item_index_list.push(itemData.list.length - 1);
        this.f_createIndexForAllItems(this.tree_list_data.list, { 'item_index': [] });
        this.fSelectThisItem(item_index_list);
        this.render_template();
      }
    },
    fSelectThisItem (item_index_list) {
      // mevcut index seçimini silip yeni indexi seçililere ekliyoruz.
      this.selected_items.splice(0, item_index_list.length);
      this.selected_items.push(item_index_list.join());
    },
    f_getTreeLocation: function (item_index_list, type) {
      let listLoc = this.tree_list_data.list;
      for (let i in item_index_list) {
        if (parseInt(i) !== item_index_list.length - 1) {
          listLoc = listLoc[item_index_list[i]].list;
          // console.log('listLoc: ', listLoc);
        } else {
          if (type === 'item_data') {
            return listLoc[item_index_list[i]];
          } else if (type === 'list') {
            // console.log('listLoc:', listLoc);
            return listLoc;
          }
        }
      }
      if (type === 'list') {
        return listLoc;
      } else if (type === 'item_data') {
        return this.tree_list_data;
      }
    },
    f_createIndexForAllItems: function (list_data, result) {
      for (let i in list_data) {
        if (parseInt(i) === 0) {
          result.item_index.push(i);
        } else {
          result.item_index[result.item_index.length - 1] = i;
        }
        list_data[i].index = result.item_index.join(',');
        if ('list' in list_data[i] && list_data[i].list.length > 0) {
          this.f_createIndexForAllItems(list_data[i].list, result);
          result.item_index.splice(result.item_index.length - 1, 1);
        }
      }
    },
    fCalculateAddIconSpanList: function (tree_deep_count) {
      let html_str = '';
      for (let i = 0; i <= tree_deep_count; i++) {
        if (i === tree_deep_count) {
          html_str += '<span style="padding-left: 20px; border-left: dotted 1px #20a8d8; border-bottom: dotted 1px #20a8d8;"></span>';
        } else {
          html_str += '<span style="padding-left: 20px; border-left: dotted 1px #20a8d8;"></span>';
        }
      }
      return html_str;
    }
  },
  watch: {}
};

</script>

<style>


</style>

