import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  saveTreeList (data_type, location, username, hospital_group, hospital_code, data) {
    return Vue.http.put(API_BASE_URL + 'saveTreeList/' + data_type + '/location/' + location + '/user/' + username + '/hospital_group/' + hospital_group + '/hospital_code/' + hospital_code, data);
  },
  getTreeList (query) {
    return Vue.http.get(API_BASE_URL + 'getTreeList?' + query);
  },
};
