<template>
  <div style="white-space: nowrap;">
    <b-row :key="'item-' + item_index.join()" @mouseover="hover = true" @mouseleave="hover = false">
      <b-col cols="12">
        <template v-for="(it, it_ind) in item_index.length">
          <span v-if="it === item_index.length" style="padding-left: 20px; border-left: dotted 1px #20a8d8; border-bottom: dotted 1px #20a8d8;"></span>
          <span v-else style="padding-left: 20px; border-left: dotted 1px #20a8d8;"></span>
        </template>
        <i class="fa fa-hand-paper-o" v-if="item.isTitle && item.isTitle === 'yes'" style="color: red;"></i>
        <i class="fa fa-hand-o-right" v-if="item.isTitle && item.isTitle === 'no'" style="color: green;"></i>
        <template v-if="selected_items.indexOf(item_index.join()) !== -1">
          <span :style="my_item_index_list.indexOf(item_index.join(',')) !== -1 ? 'color: #20a8d8; font-weight:bold; cursor: pointer; border: solid 1px black; border-radius: 5px;' : 'color: #20a8d8; font-weight:bold; cursor: pointer;'" @click="fSelectItem()">
            {{ item_index[item_index.length - 1] + 1 }} . 
            {{ item.label }}
            <template v-if="item.owner_type"> <i>[ {{ item.owner_type }} ]</i> </template>
          </span>
          <b-dropdown variant="primary" size="sm" v-if="mode === 'edit' && tree_list_type !== 'task_tree_list'">
            <template v-if="f_controlMyLayerPermission('can_manage_sub_layer')">
              <b-dropdown-item @click="list_item_order('up')"><i class="fa fa-angle-up"></i>yukarı</b-dropdown-item>
              <b-dropdown-item @click="list_item_order('down')"><i class="fa fa-angle-down"></i>aşağı</b-dropdown-item>
              <b-dropdown-item @click="list_item_order('top')"><i class="fa fa-angle-double-up"></i>en üste</b-dropdown-item>
              <b-dropdown-item @click="list_item_order('bottom')"><i class="fa fa-angle-double-down"></i>en aşağı</b-dropdown-item>
              <b-dropdown-item @click="fMoveHere('up')"><i class="fa fa-arrow-up"></i>Üzerine Taşı</b-dropdown-item>
              <b-dropdown-item @click="fMoveHere('down')"><i class="fa fa-arrow-down"></i>Altına Taşı</b-dropdown-item>
              <b-dropdown-item v-if="tree_list_type !== 'organization' || f_controlIsDeletable()" @click="delete_list_item()"><i class="fa fa-trash"></i>sil</b-dropdown-item>
              <b-dropdown-item v-if="item_index.length > 1" @click="fMoveItemSelect()"><i class="fa fa-arrows-alt"></i>taşı</b-dropdown-item>
            </template>
            <template v-if="f_controlMyLayerPermission('can_manage_sub_layer', true)">
              <b-dropdown-item @click="fMoveHere('down_layer')"><i class="fa fa-arrow-down"></i>Alt Katmana Taşı</b-dropdown-item>
            </template>
            <!-- ilk sıra level düzeyinde değil ise taşınabilir. -->
          </b-dropdown>
        </template>
        <template v-else>
          <span :style="my_item_index_list.indexOf(item_index.join(',')) !== -1 ? 'cursor: pointer; border: solid 1px black; border-radius: 5px;' : 'cursor: pointer;'" @click="fSelectItem()">
            {{ item_index[item_index.length - 1] + 1 }} . {{ item.label }}
            <template v-if="item.owner_type"> <i>[ {{ item.owner_type }} ]</i> </template>
          </span>
        </template>
        <template v-if="mode === 'edit' && f_controlMyLayerPermission('can_manage_sub_layer') && tree_list_type !== 'task_tree_list'">
          <span v-if="fIsEqualToMoveItem()" @click="move_item.splice(0, move_item.length)"><i class="fa fa-arrows-alt" title="TAŞIMAYI İPTAL ET" style="color: green; cursor: pointer; padding-left: 10px;"></i></span>
          <span v-if="!fIsEqualToMoveItem() && (hover || selected_items.indexOf(item_index.join()) !== -1)" @click="fMoveItemSelect()"><i class="fa fa-arrows-alt" title="TAŞI" style="color: red; cursor: pointer; padding-left: 10px;"></i></span>
          <span v-if="!fIsEqualToMoveItem() && (move_item.length > 0) && (hover || selected_items.indexOf(item_index.join()) !== -1)" @click="fMoveHere('down')"><i class="fa fa-arrow-down" :title="'ALTINA TAŞI [ ' + f_getTreeLocation(move_item, 'item_data').label + '] '" style="color: green; cursor: pointer; padding-left: 10px;"></i></span>
          <span v-if="!fIsEqualToMoveItem() && (move_item.length > 0) && (hover || selected_items.indexOf(item_index.join()) !== -1)" @click="fMoveHere('up')"><i class="fa fa-arrow-up" :title="'ÜZERİNE TAŞI [ ' + f_getTreeLocation(move_item, 'item_data').label + '] '" style="color: green; cursor: pointer; padding-left: 10px;"></i></span>
          <span v-if="!fIsEqualToMoveItem() && (move_item.length > 0) && (hover || selected_items.indexOf(item_index.join()) !== -1)" @click="fMoveHere('down_layer')"><i class="fa fa-arrow-down" :title="'ALT KATMANINA TAŞI [ ' + f_getTreeLocation(move_item, 'item_data').label + '] '" style="color: green; cursor: pointer; padding-left: 10px;"></i></span>
          <span v-if="(hover || selected_items.indexOf(item_index.join()) !== -1)" @click="list_item_order('up')"><i class="fa fa-chevron-up" title="YUKARI" style="color: #20a8d8; cursor: pointer; padding-left: 10px;"></i></span>
          <span v-if="(hover || selected_items.indexOf(item_index.join()) !== -1)" @click="list_item_order('down')"><i class="fa fa-chevron-down" title="AŞAĞI" style="color: #20a8d8; cursor: pointer; padding-left: 10px;"></i></span>
          <span v-if="(hover || selected_items.indexOf(item_index.join()) !== -1)" @click="list_item_order('top')"><i class="fa fa-arrow-circle-up" title="EN YUKARI" style="color: green; cursor: pointer; padding-left: 10px;"></i></span>
          <span v-if="(hover || selected_items.indexOf(item_index.join()) !== -1)" @click="list_item_order('bottom')"><i class="fa fa-arrow-circle-down" title="EN AŞAĞI" style="color: green; cursor: pointer; padding-left: 10px;"></i></span>
        </template>
      </b-col>
    </b-row>
    <template v-if="tree_list_type === 'module_and_model' && item.is_model && edit_user">
      <template v-if="edit_user && item.owner && item.owner.value === edit_user">
        <b-row>
          <b-col cols="12">
            <template v-for="(it, it_ind) in item_index.length">
              <span v-if="it === item_index.length" style="padding-left: 40px; border-left: dotted 1px #20a8d8; border-bottom: dotted 1px #20a8d8;"></span>
              <span v-else style="padding-left: 20px; border-left: dotted 1px #20a8d8;"></span>
            </template>
            <span style="color: red;">Bu kullanıcı veri modelinin sahibidir. Yetki düzenlemesi yapılamaz.</span>
          </b-col>
        </b-row>
      </template>
      <template v-else-if="pdt && pdt.wisdom_data_model[item.value]">
        <template v-if="(item.permission_by_superuser && item.permission_by_superuser === 'yes') || item.owner_type === 'hospital'">
          <b-row>
            <b-col cols="12">
              <template v-for="(it, it_ind) in item_index.length">
                <span v-if="it === item_index.length" style="padding-left: 40px; border-left: dotted 1px #20a8d8; border-bottom: dotted 1px #20a8d8;"></span>
                <span v-else style="padding-left: 20px; border-left: dotted 1px #20a8d8;"></span>
              </template>
              <i style="color: #20a8d8;">Yetki Listesi</i>
            </b-col>
          </b-row>
          <b-form-checkbox-group v-if="(pdt.wisdom_data_model[item.value].owner_type === item.owner_type)" v-model="pdt.wisdom_data_model[item.value].permission">
            <b-row v-for="(perm, perm_ind) in item.permissions_list_for_superuser" :key="'item-permission-' + item_index.join() + perm_ind">
              <b-col cols="12">
                <template v-for="(it, it_ind) in item_index.length">
                  <span v-if="it === item_index.length" style="padding-left: 40px; border-left: dotted 1px #20a8d8; border-bottom: dotted 1px #20a8d8;"></span>
                  <span v-else style="padding-left: 20px; border-left: dotted 1px #20a8d8;"></span>
                </template>
                <b-form-checkbox style="display: inline;" :value="perm">{{ perm }}</b-form-checkbox>
              </b-col>
            </b-row>
          </b-form-checkbox-group>
        </template>
        <template v-else-if="item.permissions_list_for_superuser && item.permissions_list_for_superuser.length > 0">
          <b-row>
            <b-col cols="12">
              <template v-for="(it, it_ind) in item_index.length">
                <span v-if="it === item_index.length" style="padding-left: 40px; border-left: dotted 1px #20a8d8; border-bottom: dotted 1px #20a8d8;"></span>
                <span v-else style="padding-left: 20px; border-left: dotted 1px #20a8d8;"></span>
              </template>
              Bu veri modeline kullanım yetkisi verilememektedir.
            </b-col>
          </b-row>
          <b-row v-for="(perm, perm_ind) in pdt.wisdom_data_model[item.value].permission" :key="'pdt.wisdom_data_model_item.value_.permission_' + perm_ind">
            <b-col cols="12">
              <template v-for="(it, it_ind) in item_index.length">
                <span v-if="it === item_index.length" style="padding-left: 40px; border-left: dotted 1px #20a8d8; border-bottom: dotted 1px #20a8d8;"></span>
                <span v-else style="padding-left: 20px; border-left: dotted 1px #20a8d8;"></span>
              </template>
              {{ perm }}
            </b-col>
          </b-row>
        </template>
      </template>
      <template v-else>
        <b-row>
          <b-col cols="12">
            <template v-for="(it, it_ind) in item_index.length">
              <span v-if="it === item_index.length" style="padding-left: 40px; border-left: dotted 1px #20a8d8; border-bottom: dotted 1px #20a8d8;"></span>
              <span v-else style="padding-left: 20px; border-left: dotted 1px #20a8d8;"></span>
            </template>
            <b-button @click="f_prepareDataModelPermissionList()" size="sm" variant="success">Verilebilecek yetkileri göster</b-button>
          </b-col>
        </b-row>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DepartmentService from '@/services/department';
import {
  default as Modal
} from '@/components/widgets/Modal';

export default {
  name: 'TreeListItem',
  components: {
    Modal
  },
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      device: 'device',
      wdm: 'wdm'
    }),
    valueRegexCheck (val) {
      const regex = /^[\-\_a-z0-9]*$/ig;
      if (val) {
        let regexValue = regex.exec(val);
        if (regexValue && (val === regexValue[0])) {
          return true;
        }
      }
      return false;
    }
  },
  props: {
    tree_list_type: {
      type: String,
      required: true
    },
    tree_list_data: {
      type: Object,
      required: true,
      default: {}
    },
    change: {
      type: Object,
      required: true
    },
    selected_item_data: {
      type: Object,
      required: false
    },
    item: {
      type: Object,
      required: true
    },
    item_index: {
      type: Array,
      required: true
    },
    list: {
      type: Array,
      required: true
    },
    selected_items: {
      type: Array,
      required: true
    },
    my_item_index_list: {
      type: Array,
      required: false
    },
    move_item: {
      type: Array,
      required: true
    },
    select_type: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
    pdt: {
      type: Object,
      required: false
    },
    edit_user: {
      type: String,
      required: false,
      default: ''
    },
    follow_up_location: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      hover: false,
      show_html: '',
      selected_item_index: '',
      level: 0
    }
  },
  created: function () {},
  mounted: function () {
    // this.show_html = this.fBuildTreeList(this.tree_list_data.list);
  },
  methods: {
    f_controlIsDeletable: function () {
      // wca_start
      // This function controls to delete an item. If it has task_list, you cannot delete.
      // wca_end
      if (this.item.task_list && this.item.task_list.length > 0) {
        return false;
      }
      if (this.item.list && this.item.list.length > 0) {
        // wca_start
        // This layer has no task_list but if its any of lower layers have task_list, we cannot delete this layer
        // wca_end
        let x = { 'y': false };
        this.f_controlHasTaskList(this.item.list, x);
        /*
        if (this.item.index === '0,0,0,0,1') {
          console.log(x);
        }
        */
        if (x['y']) {
          return false;
        }
      }
      return true;
    },
    f_controlHasTaskList: function (list_data, x) {
      if (!x['y']) {
        for (let i in list_data) {
          /*
          if (this.item.index === '0,0,0,0,1') {
            console.log(list_data[i]);
          }
          */
          if (list_data[i].task_list && list_data[i].task_list.length > 0) {
            x['y'] = true;
            break;
          }
          if (list_data[i].list && list_data[i].list.length > 0) {
            this.f_controlHasTaskList(list_data[i].list, x);
          }
        }
      }
    },
    f_controlMyLayerPermission: function (perm, can_use_me = false) {
      // aktif kullanıcının my_item_index_listi oluşturulduğundan bu kullanıcının hem yetkisi hem de amir olup olmadığı kontrol ediliyor.
      // bir aktif kullanıcı başka bir kullanıcının üzerinde birden fazla lokalizasyonda olabilir ve bu lokalizasyonlardaki izinleri de farklı olabilir
      // dolayısıyla önce bu izinlerini perm_liste topluyoruz.
      if (this.tree_list_type !== 'organization') {
        return true;
      }
      let det = false;
      let perm_list = [];
      for (let i in this.my_item_index_list) {
        // wca_start
        // example:
        // this.item_index =  [0,1,2], this.my_item_index_list = ['0,1,2', '0,1,5']
        // wca_end
        if (this.item_index.join(',').indexOf(this.my_item_index_list[i]) === 0) {
          if (!can_use_me) {
            if (this.my_item_index_list[i] !== this.item_index.join(',')) {
              let itemData = this.f_getTreeLocation(this.my_item_index_list[i].split(','), 'item_data');
              if (itemData.perms && itemData.perms.length > 0) {
                for (let i in itemData.perms) {
                  if (perm_list.indexOf(itemData.perms[i]) === -1) {
                    perm_list.push(itemData.perms[i]);
                  }
                }
              }
            }
          } else {
            let itemData = this.f_getTreeLocation(this.my_item_index_list[i].split(','), 'item_data');
            if (itemData.perms && itemData.perms.length > 0) {
              for (let i in itemData.perms) {
                if (perm_list.indexOf(itemData.perms[i]) === -1) {
                  perm_list.push(itemData.perms[i]);
                }
              }
            }
          }
        }
      }
      if (perm_list.indexOf(perm) !== -1) {
        return true;
      }
      return false;
    },
    f_prepareDataModelPermissionList: function () {
      if (this.pdt.wisdom_data_model && !this.pdt.wisdom_data_model[this.item.value]) {
        this.pdt.wisdom_data_model[this.item.value] = {
          'owner_type': this.item.owner_type,
          'data_type': this.item.value,
          'name': this.item.name,
          'edit_in_hospital_settings': this.item.edit_in_hospital_settings,
          'permission': JSON.parse(JSON.stringify(this.item.permissions_list_for_superuser))
        };
      }
      this.$forceUpdate();
    },
    fIsEqualToMoveItem: function () {
      // console.log('opt:', opt, 'carry_option:', this.carry_option);
      return JSON.stringify(this.item_index) === JSON.stringify(this.move_item) ? true : false;
    },
    fMoveHere: function (type) {
      let fromList = this.f_getTreeLocation(this.move_item, 'list');
      let toList = this.f_getTreeLocation(this.item_index, 'list');
      let itemData = this.f_getTreeLocation(this.move_item, 'item_data');
      fromList.splice(this.move_item[this.move_item.length - 1], 1);
      this.move_item.splice(0, this.move_item.length);
      if (type === 'down') {
        // bu item listedeki son değer ise
        if (this.item_index[this.item_index.length - 1] === this.list.length - 1) {
          toList.push(itemData);
        } else { // bu item listede aralarda bir indexte ise
          toList.splice(this.item_index[this.item_index.length - 1] + 1, 0, itemData);
        }
      } else if (type === 'up') {
        toList.splice(this.item_index[this.item_index.length - 1], 0, itemData);
      } else if (type === 'down_layer') {
        let to_item_data = this.f_getTreeLocation(this.item_index, 'item_data');
        if (!to_item_data.list) {
          to_item_data.list = [];
        }
        to_item_data.list.push(itemData);
      }
    },
    fMoveItemSelect: function () {
      this.move_item.splice(0, this.move_item.length);
      for (let i in this.item_index) {
        this.move_item.push(this.item_index[i]);
      }
    },
    fSendChangeToParent: function () {
      if (this.change.x === 1) {
        this.change.x = 0;
      } else {
        this.change.x = 1;
      }
    },
    fSelectItem: function () {
      if (this.tree_list_type === 'module_and_model' && this.item.is_model) {
        if (this.edit_user && this.item.owner && this.item.owner.value === this.edit_user) {
          alert('Bu kullanıcı bu modelin sahibidir. Yetkilerini düzenleyemezsiniz.');
          return;
        }
        if (this.selected_items.indexOf(this.item_index.join()) !== -1 && this.pdt) {
          this.$delete(this.pdt.wisdom_data_model, this.item.value);
        }
      }
      if (this.mode === 'use') {
        let itemData = this.f_getTreeLocation(this.item_index, 'item_data');
        if (itemData.isTitle === 'yes') {
          alert('Sadece gruplandırma başlığı, lütfen uygun seçeneğe tıklayınız');
          return;
        }
        if (this.select_type === 'multi') {
          if (this.selected_items.indexOf(this.item_index.join()) === -1) {
            this.selected_items.push(this.item_index.join());
          } else {
            this.selected_items.splice(this.selected_items.indexOf(this.item_index.join()), 1);
          }
        } else if (this.select_type === 'one') {
          this.selected_items.splice(0, 1);
          this.selected_items.push(this.item_index.join());
        }
      } else { // edit
        if (this.select_type === 'multi') {
          if (this.selected_items.indexOf(this.item_index.join()) === -1) {
            this.selected_items.push(this.item_index.join());
          } else {
            this.selected_items.splice(this.selected_items.indexOf(this.item_index.join()), 1);
          }
        } else if (this.select_type === 'one') {
          this.selected_items.splice(0, 1);
          this.selected_items.push(this.item_index.join());
        }
      }
      this.$forceUpdate();
    },
    fBuildTreeList: function (list) {
      let html_str = '';
      html_str += this.fCreateTreeList(list);
      return html_str;
    },
    fCreateTreeList: function (list, item_index = '') {
      let html_str = '';
      for (let i in list) {
        let index = item_index + i;
        html_str += '<div class="row" key="' + index + '">';
        html_str += '<div class="col-sm-12">';
        if (list[i].isTitle && list[i].isTitle === 'yes') {
          html_str += '<i class="fa fa-hand-paper-o"></i>';
        } else if (list[i].isTitle && list[i].isTitle === 'no') {
          html_str += '<i class="fa fa-hand-o-right"></i>';
        }
        if (this.selected_item_index === index) {
          html_str += '<span style="color: #20a8d8; font-weight:bold; cursor: pointer;"> ' + (parseInt(i) + 1).toString() + '.' + list[i].label + ' </span>';
          html_str += '<b-dropdown variant="primary" size="sm">';
          html_str += '<b-dropdown-item @click="list_item_order(' + index + ', \'up\')"><i class="fa fa-angle-up"></i>{{ $t(\'wdm16.13004\') }}</b-dropdown-item>';
          html_str += '<b-dropdown-item @click="list_item_order(' + index + ', \'down\')"><i class="fa fa-angle-down"></i>{{ $t(\'wdm16.905\') }}</b-dropdown-item>';
          html_str += '<b-dropdown-item @click="list_item_order(' + index + ', \'top\')"><i class="fa fa-angle-double-up"></i>{{ $t(\'wdm16.13182\') }}</b-dropdown-item>';
          html_str += '<b-dropdown-item @click="list_item_order(' + index + ', \'bottom\')"><i class="fa fa-angle-double-down"></i>{{ $t(\'wdm16.13006\') }}</b-dropdown-item>';
          html_str += '</b-dropdown>';
        } else {
          html_str += '<span style="cursor: pointer;" @click="fSelectItem(\'' + index + '\')"> ' + (parseInt(i) + 1).toString() + '.' + list[i].label + ' </span>';
        }
        if (this.level > 0) {
          html_str += '';
        }
        html_str += '</div>';
        html_str += '</div>';
        if (list[i].list && list[i].list.length > 0) {
          this.level += 1;
          html_str += this.fCreateTreeList(list[i].list, index);
        }
      }
      this.level -= 1;
      return html_str;
    },
    delete_list_item: function () {
      if (confirm('Silmeniz halinde alt katmanlar da silinecektir.')) {
        let list = this.f_getTreeLocation(this.item_index, 'list');
        list.splice(this.item_index[this.item_index.length - 1], 1);
        this.selected_items.splice(this.selected_items.indexOf(this.item_index.join()), 1);
        this.fSendChangeToParent();
      }
    },
    f_getTreeLocation: function (item_index_list, type) {
      let listLoc = this.tree_list_data.list;
      for (let i in item_index_list) {
        if (parseInt(i) !== item_index_list.length - 1) {
          listLoc = listLoc[item_index_list[i]].list;
          // console.log('listLoc: ', listLoc);
        } else {
          if (type === 'item_data') {
            return listLoc[item_index_list[i]];
          } else if (type === 'list') {
            // console.log('listLoc:', listLoc);
            return listLoc;
          }
        }
      }
      if (type === 'list') {
        return listLoc;
      } else if (type === 'item_data') {
        return this.tree_list_data;
      }
    },
    list_item_order: function (direction) {
      // wca_start
      // direction => up, down, top, bottom
      // this.item_index[this.item_index.length - 1] => We are taking the active item's index location from its own list
      // wca_end
      this.list_param_order(this.f_getTreeLocation(this.item_index, 'list'), this.item_index[this.item_index.length - 1], direction);
    },
    fSelectThisItem (new_item_index_list) {
      // mevcut index seçimini silip yeni indexi seçililere ekliyoruz.
      this.selected_items.splice(this.selected_items.indexOf(this.item_index.join()), 1);
      this.selected_items.push(new_item_index_list.join());
    },
    list_param_order: function (list, index, direction) {
      // console.log('list:', list, 'index:', index, 'direction:', direction);
      let index_data = list[index];
      let count = list.length;
      let friend = '';
      let new_item_index_list = JSON.parse(JSON.stringify(this.item_index));
      if (direction === 'up') {
        if (index !== 0) {
          friend = list[index - 1];
          list[index - 1] = index_data;
          list[index] = friend;
          // yeni index listi oluşturuyoruz.
          new_item_index_list[new_item_index_list.length - 1] = new_item_index_list[new_item_index_list.length - 1] - 1;
          this.fSelectThisItem(new_item_index_list);
        }
      } else if (direction === 'down') {
        if (index !== count - 1) {
          friend = list[index + 1];
          list[index + 1] = index_data;
          list[index] = friend;
          // yeni index listi oluşturuyoruz.
          new_item_index_list[new_item_index_list.length - 1] = new_item_index_list[new_item_index_list.length - 1] + 1;
          this.fSelectThisItem(new_item_index_list);
        }
      } else if (direction === 'top') {
        list.splice(index, 1);
        list.splice(0, 0, index_data);
        // yeni index listi oluşturuyoruz.
        new_item_index_list[new_item_index_list.length - 1] = 0;
        this.fSelectThisItem(new_item_index_list);
      } else if (direction === 'bottom') {
        list.splice(index, 1);
        list.push(index_data);
        // yeni index listi oluşturuyoruz.
        new_item_index_list[new_item_index_list.length - 1] = list.length - 1;
        this.fSelectThisItem(new_item_index_list);
      }
      this.fSendChangeToParent();
    }
  },
  watch: {}
}

</script>

<style type="text/css">


</style>

